var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"wrapper home_wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row row-header"},[_c('div',{staticClass:"col-sm-12"},[_c('toggle-button',{attrs:{"value":_vm.isDarkMode,"labels":{checked: 'Light', unchecked: 'Dark'},"color":{checked: '#989898', unchecked: '#383838'},"width":60},on:{"change":_vm.toggleTheme}})],1)]),_c('div',{staticClass:"row row-margin-bottom"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"graphBox box"},[_c('h3',[_vm._v(_vm._s(_vm.$t('transactionList-screen.transactionDetailsLabel'))+" "+_vm._s(this.transactionId))]),_c('b',[_vm._v(_vm._s(_vm.$t('transactionList-screen.createdAtTimeLabel')))]),_vm._v(" "+_vm._s(_vm.transactionData.created_at_time)+" "),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$t('transactionList-screen.confirmedLabel')))]),_vm._v(" "+_vm._s(_vm.transactionData.confirmed)+" "),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$t('transactionList-screen.confirmedAtHeighLabel')))]),_vm._v(" "+_vm._s(_vm.transactionData.confirmed_at_height)),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$t('transactionList-screen.rewardAddressLabel')))]),_vm._v(" "+_vm._s(_vm.transactionData.fee_amount)),_c('br')])])]),_c('div',{staticClass:"row row-margin-bottom"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"box box-padding"},[_c('div',{staticClass:"h1"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.transactionTableData,"sort-options":{
                            enabled: true
                          },"pagination-options":{
                            enabled: true,
                            mode: 'records',
                            perPage: 10,
                            position: 'bottom',
                            perPageDropdown: [20, 50, 100],
                            dropdownAllowAll: false,
                            setCurrentPage: 1,
                            nextLabel: 'next',
                            prevLabel: 'prev',
                            rowsPerPageLabel: 'Lines per page',
                            ofLabel: 'of',
                            pageLabel: 'page', 
                            allLabel: 'All' 
                          }}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }