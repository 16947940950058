import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Memberlist from '../views/Memberlist.vue'
import Membersearch from '../views/Membersearch.vue'
import Myfarm from '../views/Myfarm.vue'
import Poolrewards from '../views/Poolrewards.vue'
import Poolearnings from '../views/Poolearnings.vue'
import PayoutList from '../views/PayoutList.vue'
import TransactionList from '../views/TransactionList.vue'
import Login from '../views/authentication/Login.vue'
import Settings from '../views/Settings.vue'
import Coupon from '../views/Coupon.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/memberlist',
        name: 'Memberlist',
        component: Memberlist
    },
    {
        path: '/membersearch',
        name: 'Membersearch',
        component: Membersearch
    },
    {
        path: '/myfarm',
        name: 'Myfarm',
        component: Myfarm
    },
    {
        path: '/poolrewards',
        name: 'Poolrewards',
        component: Poolrewards
    },
    {
        path: '/poolearnings',
        name: 'Poolearnings',
        component: Poolearnings
    },
    {
        path: '/poolrewards/payout',
        name: 'PayoutList',
        component: PayoutList
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings
    },
    {
        path: '/poolrewards/payout/transaction/:transaction_id',
        name: 'TransactionList',
        component: TransactionList
    },
    {
        path: '/coupon',
        name: 'Coupon',
        component: Coupon
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router