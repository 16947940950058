<template>
    <section class="wrapper home_wrapper">
        <div class="container">
            <div class="row row-header">
                <div class="col">
                    <toggle-button class="toggle-align" @change="toggleTheme" :value="isDarkMode" :labels="{checked: ' Light ', unchecked: ' Dark '}" :color="{checked: '#989898', unchecked: '#383838'}" :width="60" />
                </div>
                <!-- Activate to show Mobile App link -->
                <div v-if="this.$configJson.Coin == 'XCH'" class="col">
                    <a href ="https://apps.apple.com/app/id1588693066"><img src="@/assets/images/app-store.png" alt="" width="150" class="store-logo" /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.xchpool"><img src="@/assets/images/google-play.png" alt="" width="150" class="store-logo" /></a>
                </div>

            </div>
            <div class="row row-header">
                <div v-if="this.$configJson.Coin == 'XCC'" class="col-9 horizontal-align">
                    <div class="h3 align-left"> {{ $t('home-screen.joinTitleChives') }} <span class="poolUri">{{this.$configJson.PoolUrl}} </span></div>
                </div>
                <div v-if="this.$configJson.Coin == 'XCH'" class="col-9 horizontal-align">
                    <div class="h3 "> {{ $t('home-screen.joinTitle') }} <span class="poolUri">{{this.$configJson.PoolUrl}} </span></div>
                </div>

                <div class="col-2">
                    <img v-if="this.$configJson.Coin == 'XCC'" src="@/assets/images/chives.png" height="50px" class="store-logo" />
                    <img v-if="this.$configJson.Coin == 'XCH'" src="@/assets/images/chia.png" height="50px" class="store-logo" />
                </div>
            </div>
            
            <div class="row">
                <div class="col-6 col-sm-3">
                    <div class="graphBox-flat box">
                        <h5>{{ $t('home-screen.mobileAppTitle') }}</h5>
                        <p>{{ $t('home-screen.mobileAppDescription') }}</p>
                    </div>
                </div>
                <div class="col-6 col-sm-3">
                    <div class="graphBox-flat box">
                        <h5>{{ $t('home-screen.notificationsTitle') }}</h5>
                        <p>{{ $t('home-screen.notificationsDescription') }}</p>
                    </div>
                </div>
                <div class="col-6 col-sm-3">
                    <div class="graphBox-flat box">
                        <h5>{{ $t('home-screen.taxReportingTitle') }}</h5>
                        <p>{{ $t('home-screen.taxReportingDescription') }}</p>
                    </div>
                </div>
                <div class="col-6 col-sm-3">
                    <div class="graphBox-flat box">
                        <h5>{{ $t('home-screen.helpfulCommunityTitle') }}</h5>
                        <p>{{ $t('home-screen.helpfulCommunityDescription') }}</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-3">
                    <div class="graphBox box">
                        <h5>{{ $t('home-screen.poolCapacityTitle') }}</h5>
                        <p>{{ $t('home-screen.poolCapacityDescription') }}</p>
                        <div class="h1 text-center capacity_bytes">{{ poolStats.poolCapacityBytes }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                    <div class="graphBox box box-margin">
                        <h5>{{ $t('home-screen.membersTitle') }}</h5>
                        <p>{{ $t('home-screen.membersDescription') }}</p>
                        <div class="h1 text-center">{{ poolStats.poolMembers }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-9">
                    <div class="box box-padding">
                        <div class="h1 text-center">
                            <h5>{{ $t('home-screen.poolCapacityEvolutionTitle') }}</h5>
                            <apexchart ref="chartPoolGrowth" type="area" height="274" :options="chartOptionsNetspace" :series="series"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="graphBox box">
                        <h5>{{ $t('home-screen.networkCapacityTitle') }}</h5>
                        <p>{{ $t('home-screen.networkCapacityDescription') }}</p>
                        <div class="h1 text-center last_reward">{{ poolStats.blockchainTotalSpace }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="graphBox box">
                        <h5>{{ $t('home-screen.poolPayoutTitle') }}</h5>
                        <p>{{ $t('home-screen.poolPayoutDescription') }}</p>
                        <div class="h1 text-center last_reward">{{ poolStats.poolMinPayout }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="graphBox box">
                        <h5>{{ $t('home-screen.estimatedEarningsTitle') }}</h5>
                        <p>{{ $t('home-screen.estimatedEarningsDescription') }}</p>
                        <div class="h1 text-center last_reward">{{ poolStats.dailyEarningsPer100Plots }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
            </div>      
            <div class="row">
                <div class="col-sm-9">
                    <div class="box box-padding">
                        <div class="h1 text-center">
                            <h5>{{ $t('home-screen.blocksWonTitle') }}</h5>
                            <apexchart ref="chartWonBlocks" type="area" height="274" :options="chartOptionsWonBlocks" :series="series"></apexchart>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="graphBox box">
                        <h5>{{ $t('home-screen.estimated30EarningsTitle') }}</h5>
                        <p>{{ $t('home-screen.estimated30EarningsDescription') }}</p>
                        <div class="h1 text-center last_reward">{{ poolStats.thirtyDayEarningsPer100Plots }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                    <div class="graphBox box box-margin">
                        <h5>{{ $t('home-screen.luckTitle') }}</h5>
                        <p>{{ $t('home-screen.luckDescription') }}</p>
                        <div class="h1 text-center last_reward">{{ poolStats.luck }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <h5>{{this.$configJson.CoinName + ' ' + $t('home-screen.xchPriceTitle') }}</h5>
                        <p>{{this.$configJson.Coin + ' ' + $t('home-screen.xchPriceDescription') }}</p>
                        <div class="h1 text-center">
                            <apexchart ref="chartXCHPrice" type="area" height="250" :options="chartOptionsXCHPrice" :series="series"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row row-margin-bottom">
                <div class="col-sm-6">
                    <div class="box box-padding status-row">
                        <span class="service">
                            <h5>{{ $t('home-screen.poolStatusLabel') }}</h5>
                        </span>
                        <span class="service">
                            <h5 v-if="poolStatus" class="service-ok"> {{ $t('home-screen.statusOKLabel') }}</h5>
                            <h5 v-if="!poolStatus" class="service-ko"> {{ $t('home-screen.statusKOLabel') }}</h5>
                        </span>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="box box-padding status-row">
                        <span class="service">
                            <h5>{{ $t('home-screen.apiStatusLabel') }}</h5>
                        </span>
                        <span class="service">
                            <h5 v-if="apiStatus" class="service-ok"> {{ $t('home-screen.statusOKLabel') }}</h5>
                            <h5 v-if="!apiStatus" class="service-ko"> {{ $t('home-screen.statusKOLabel') }}</h5>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import https from 'https';
    import moment from 'moment';
    import getUserLocale from 'get-user-locale';

    export default {
        name: 'Home',
        data() {        
            return {
                theme: '',
                isDarkMode: false,
                poolStatus: true,
                apiStatus: true,
                xchPrice: [],
                poolStats: {},
                poolCapacityChart: [],
                poolWinBlocks: [],
                poolEstimatedWinBlocks: [],
                series: [],
                chartOptionsNetspace: {
                    chart: { height: 250, type: 'area'},
                    dataLabels: { enabled: true},
                    stroke: { curve: 'smooth' },
                    noData: { text: '...'},
                    colors : ["#06a74d"]
                    },
                chartOptionsWonBlocks: {
                    chart: { height: 250, type: 'line'},
                    dataLabels: { enabled: false},
                    stroke: { curve: 'smooth' },
                    yaxis: { min: 0 },
                    noData: { text: '...'},
                    colors : ["#06a74d", "#808080"]
                    },
                chartOptionsXCHPrice: {
                    chart: { height: 250, type: 'area'},
                    dataLabels: { enabled: false },
                    stroke: { curve: 'smooth' },
                    noData: { text: '...' },
                    colors : ["#06a74d"]
                    }
            }
        },

        methods: {            
            async getPoolStats() {
                let userLocale = getUserLocale();
                const agent = new https.Agent({ rejectUnauthorized: false });
                this.$axios.get("v1/poolstats", {httpsAgent: agent})
                .then( res => {
                    moment.locale(userLocale);
                    this.poolStats = res.data;
                    this.poolStatus = this.poolStats.blockchainSynced;
                    this.poolStats.poolCapacityBytes = this.getFormatedPoolBytes(this.poolStats.poolCapacityBytes);
                    this.poolStats.blockchainTotalSpace = this.getFormatedPoolBytes(this.poolStats.blockchainTotalSpace);
                    this.poolStats.lastReward = moment(this.poolStats.lastReward).utc().format("YYYY-MM-DD LT");
                    
                    //Luck
                    if(this.poolStats.luck < 0.84 ){ this.poolStats.luck = "Very lucky"}
                    else if(this.poolStats.luck >= 0.84 && this.poolStats.luck < 0.92 ){ this.poolStats.luck = "Lucky" }
                    else if(this.poolStats.luck >= 0.92 && this.poolStats.luck < 1.08 ){ this.poolStats.luck = "Average" }
                    else if(this.poolStats.luck >= 1.08 && this.poolStats.luck < 1.16 ){ this.poolStats.luck = "Unlucky" }
                    else if(this.poolStats.luck >= 1.16){ this.poolStats.luck = "Very Unlucky" }

                    // Capacity Chart
                    this.poolStats.capacityChart.forEach(element => {
                        moment.locale(userLocale);
                        element.timestamp = moment(parseInt(element.timestamp * 1000)).utc().format("YYYY-MM-DD");
                        element.netspace = this.getFormatedPoolBytesWithoutSize(element.netspace);
                        this.poolCapacityChart.push({ "x" : element.timestamp, "y" : element.netspace });
                    });
                    this.$refs.chartPoolGrowth.updateSeries([{
                        name: this.$i18n.t('home-screen.growthLabel'),
                        data: this.poolCapacityChart
                    }]);

                    // Won Blocks Chart
                    this.poolStats.wonBlocks.forEach(element => {
                        moment.locale(userLocale);
                        element.timestamp = moment(parseInt(element.timestamp * 1000)).utc().format("YYYY-MM-DD");
                        this.poolWinBlocks.push({ "x" : element.timestamp, "y" : element.blocks });
                        this.poolEstimatedWinBlocks.push({ "x" : element.timestamp, "y" : element.expected });
                    }); 
                    this.$refs.chartWonBlocks.updateSeries([
                        { type: 'bar', name: this.$i18n.t('home-screen.blocksLabel'), data: this.poolWinBlocks},
                        { type: 'line', name: "Estimated", data: this.poolEstimatedWinBlocks }
                    ]);

                    // Price Chart
                    this.poolStats.priceChart.prices.forEach(element => {
                        moment.locale(userLocale);
                        element[0] = moment(parseInt(element[0])).utc().format("YYYY-MM-DD LT");
                        element[1] = element[1].toFixed(4)
                        this.xchPrice.push({ "x" : element[0], "y" : element[1] });
                    }); 
                    this.$refs.chartXCHPrice.updateSeries([{ name: '$', data: this.xchPrice}]);
                });
            },
            
            getDateFromTimestamp(int,timestamp){
                let myDate = new Date(timestamp.seconds * 1000);
                return myDate;
            },

            getFormatedPoolBytes(bytes,decimals = 2) {
                if (bytes === 0) return "0 Bytes";
                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                var sizes = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB"];
                const i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i))).toFixed(dm) + " " + sizes[i];
                
            },

            getFormatedPoolBytesWithoutSize(bytes,decimals = 2) {
                if (bytes === 0) return "0 Bytes";
                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                // const i = Math.floor(Math.log(bytes) / Math.log(k));
                // console.log(i);
                return parseFloat((bytes / Math.pow(k, 5))).toFixed(dm);
            },

            toggleTheme() {
                this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
                document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
                localStorage.setItem('theme', this.theme); // stores theme value on local storage
                this.updateGraphTheme(this.theme);
            },

            updateGraphTheme(theme) {
                if(theme == 'darkMode'){
                    this.chartOptionsNetspace = {
                        theme: { mode: 'dark' },
                        colors : ["#06a74d"]
                    }
                    this.chartOptionsWonBlocks = {
                        dataLabels: { enabled: false },
                        theme: { mode: 'dark' },
                        colors : ["#06a74d", "#808080"]
                    }
                    this.chartOptionsXCHPrice = {
                        theme: { mode: 'dark' },
                        dataLabels: { enabled: false}, 
                        colors : ["#06a74d"]
                    }
                    this.isDarkMode = true;
                }else{
                    this.chartOptionsNetspace = {
                        theme: { mode: 'light' },
                        colors : ["#06a74d"]
                    }
                    this.chartOptionsWonBlocks = {
                        dataLabels: { enabled: false },
                        theme: { mode: 'light' },
                        colors : ["#06a74d", "#808080"]
                    }
                    this.chartOptionsXCHPrice = {
                        theme: { mode: 'light' },
                        dataLabels: { enabled: false },
                        colors : ["#06a74d"]
                    }
                    this.isDarkMode = false;
                }
            }
        },
        created() {
            this.getPoolStats();
            let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
            document.documentElement.setAttribute('data-theme', localTheme);
            this.theme = localTheme;
            this.updateGraphTheme(this.theme);
        }
    }
</script>