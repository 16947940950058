<template>
    <div id="app">
        <component :is="layout">
            <router-view />
        </component>
    </div>
</template>

<script>
    const defaultLayout = "layout";
    export default {
        computed: {
            layout() {
                return defaultLayout + '-layout'
            },
        },
    };
</script>