<template>
  <section class="wrapper home_wrapper">
    <div class="container">
      <div class="row row-header">
        <div class="col-sm-12">
          <toggle-button @change="toggleTheme" :value="isDarkMode" :labels="{checked: 'Light', unchecked: 'Dark'}" :color="{checked: '#989898', unchecked: '#383838'}" :width="60" />
        </div>
      </div>
      <div class="row row-margin-bottom" v-if="!showSearchResult">
        <div class="col-md-12 ">
          <div class="searchbox box">
            <h5>{{ $t('memberSearch-screen.searchLabel') }}</h5>
            <div class="form-group">
              <input
                type="search"
                class="form-control form-control-lg input-search"
                @keyup.enter="performSearch"
                v-model="searchKeyword"/>
              <button type="button" @click="performSearch">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div class="searchresult box" v-if="!showSearchResult">
            <div class="sr_title">
              <p class="text-center">{{ $t('memberSearch-screen.notFoundLabel') }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- SEARCH RESULT SECTION -->
    <div class="row row-margin-bottom">
      <div class="col-md-12 ">
          <div class="searchresult box" v-if="showSearchResult">
            <div class="sr_title">
              <h4 v-if="!searchData.nickname">{{ $t('memberSearch-screen.farmerLabel') }} : {{ searchData.singleton }}</h4>
              <h4 v-if="searchData.nickname">{{ $t('memberSearch-screen.farmerLabel') }} : {{ searchData.nickname }}</h4>
            </div>
          </div>
      </div>
    </div>
      <div class="row row-margin-bottom" v-if="showSearchResult">
          <div class="col-sm-12">
            <div class="graphBox box">
              <b>{{ $t('memberSearch-screen.launcherIDLabel') }} </b> {{searchData.singleton}} <br/>
              <b>{{ $t('memberSearch-screen.poolContractAddressLabel') }} </b> {{searchData.p2_singleton_address}}<br/>
              <b>{{ $t('memberSearch-screen.rewardAddressLabel') }}</b> {{ searchData.rewardAddress }}<br/>
              <b>{{ $t('memberSearch-screen.joindedLabel') }}</b> {{searchData.joined}} <br/>
              <b>{{ $t('memberSearch-screen.infosLabel') }}</b> {{searchData.info}} <br/>
              <div v-if="searchData.hasTooLowClientVersion">
                <br/><h4 style="color: red" >Warning! you are running a too old CHIA client version &lt; v1.3 </h4>
                <b> Join to our <a target="_blank" href="https://discord.com/invite/AzK3Z3qbek">discord</a> for more information or if you have questions</b><br/>
                <b> Chia Network official <a target="_blank" href="https://www.chia.net/2022/03/04/divided-we-fork.en.html">announcement</a> here</b><br/>
                <b> Download the <a target="_blank" href="https://www.chia.net/download/">upgrade</a> here</b>
              </div>
            </div>
          </div>
      </div>
      
      <div class="row row-margin-bottom" v-if="showSearchResult" >
                <div class="col-sm-4">
                    <div class="graphBox box">
                        <h5>{{ $t('memberSearch-screen.totalPayoutTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.totalPayoutDescription') }}</p>
                        <div class="h1 text-center capacity_bytes">{{ searchData.totalPayout }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="graphBox box">
                        <h5>{{ $t('memberSearch-screen.unpaidBalanceTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.unpaidBalanceDescription') }}</p>
                        <div class="h1 text-center">{{ searchData.unpaidBalance }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="graphBox box">
                        <h5>{{ $t('memberSearch-screen.currentPoolFeeTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.currentPoolFeeDescription') }}</p>
                        <div class="h1 text-center last_reward">{{ searchData.currentPoolFee }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
              </div>
      <div class="row row-margin-bottom" v-if="showSearchResult" >                
                <div class="col-sm-4">
                    <div class="graphBox box">
                        <h5>{{ $t('memberSearch-screen.expectedEarningsTodayTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.expectedEarningsTodayDescription') }}</p>
                        <div class="h1 text-center">{{ searchData.expectedEarningsToday }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="graphBox box">
                        <h5>{{ $t('memberSearch-screen.rankTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.rankDescription') }}</p>
                        <div class="h1 text-center">{{ searchData.rank }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="graphBox box">
                        <h5>{{ $t('memberSearch-screen.daysOfActiveFarmingTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.daysOfActiveFarmingDescription') }}</p>
                        <div class="h1 text-center">{{ searchData.days_of_active_farming }} days</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>                
            </div>
            <div class="row row-margin-bottom" v-if="showSearchResult">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <h5>{{ $t('memberSearch-screen.payoutsTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.payoutsDescription') }}</p>
                        <div class="h1 text-center">
                            <apexchart ref="chartPayouts" type="line" height="250" :options="chartOptionsPayouts" :series="dailyPayouts"></apexchart>
                        </div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
            </div>
            <div class="row row-margin-bottom" v-if="showSearchResult">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <h5>{{ $t('memberSearch-screen.detailsPayoutsTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.detailsPayoutsTitle') }}</p>
                        <div class="h1">
                          <vue-good-table
                            :columns="columnsPayout"
                            :rows="roundsList"
                            :sort-options="{
                              enabled: true,
                              initialSortBy: {field: 'date_earned', type: 'desc'}
                            }"
                            :theme="tableTheme"
                            :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 5,
                              position: 'bottom',
                              perPageDropdownEnabled: false,
                              perPageDropdown: [20, 50, 100],
                              dropdownAllowAll: false,
                              setCurrentPage: 1,
                              nextLabel: $t('memberSearch-screen.tableNextLabel'),
                              prevLabel: $t('memberSearch-screen.tablePrevLabel'),
                              rowsPerPageLabel: $t('memberSearch-screen.tableLinesPerPagesLabel'),
                              ofLabel: $t('memberSearch-screen.tableOfLabel'),
                              pageLabel: $t('memberSearch-screen.tablePageLabel'), 
                              allLabel: $t('memberSearch-screen.tableAllLabel') 
                            }">
                            <template slot="table-row" slot-scope="props"> 
                              <span v-if="props.column.field == 'transaction_id'">
                                <router-link
                                  :to="{
                                    name: 'TransactionList',
                                    params: { transaction_id: props.row.transaction_id },
                                  }"
                                  >{{ props.row.transaction_id }}</router-link>
                              </span>
                              <span v-else-if="props.column.field == 'date_earned'">
                                <router-link
                                  :to="{ name: 'PayoutList', query: { payout_id: props.row.payout_id }}"
                                  >{{ props.row.date_earned }}</router-link>
                              </span>
                              <span v-else>
                                {{props.formattedRow[props.column.field]}}
                              </span>
                            </template>
                          </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-margin-bottom" v-if="showSearchResult" >
                <div class="col-sm-4">
                    <div class="graphBox box">
                        <h5>{{ $t('memberSearch-screen.farmerNetspaceTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.farmerNetspaceDescription') }}</p>
                        <div class="h1 text-center capacity_bytes">{{ searchData.netspace }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="graphBox box">
                        <h5>{{ $t('memberSearch-screen.currentPointsTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.currentPointsTitle') }}</p>
                        <div class="h1 text-center last_reward">{{ searchData.points }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="graphBox box">
                        <h5>{{ $t('memberSearch-screen.currentPoolShareTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.currentPoolShareDescription') }}</p>
                        <div class="h1 text-center">{{ searchData.currentPoolShare }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
            </div>
      <div class="row row-margin-bottom" v-if="showSearchResult">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <h5>{{ $t('memberSearch-screen.submittedPartialsTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.submittedPartialsDescription') }}</p>
                        <div class="h1 text-center">
                            <apexchart ref="chartPartials" type="bar" height="250" :options="chartOptionsPartials" :series="seriesPartials"></apexchart>
                        </div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
            </div>
            <div class="row row-margin-bottom" v-if="showSearchResult">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <h5>{{ $t('memberSearch-screen.detailsSubmittedPartialsTitle') }}</h5>
                        <p>{{ $t('memberSearch-screen.detailsSubmittedPartialsDescription') }}</p>
                        <div class="h1">
                          <vue-good-table
                            :columns="columns"
                            :rows="partialsDetailedList"
                            :sort-options="{
                              enabled: true,
                              initialSortBy: {field: 'timestamp', type: 'desc'}
                            }"
                            :theme="tableTheme"
                            :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 10,
                              position: 'bottom',
                              perPageDropdownEnabled: false,
                              perPageDropdown: [20, 50, 100],
                              dropdownAllowAll: false,
                              setCurrentPage: 1,
                              nextLabel: $t('memberSearch-screen.tableNextLabel'),
                              prevLabel: $t('memberSearch-screen.tablePrevLabel'),
                              rowsPerPageLabel: $t('memberSearch-screen.tableLinesPerPagesLabel'),
                              ofLabel: $t('memberSearch-screen.tableOfLabel'),
                              pageLabel: $t('memberSearch-screen.tablePageLabel'), 
                              allLabel: $t('memberSearch-screen.tableAllLabel') 
                            }">
                          </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import https from "https";
import moment from "moment";
import getUserLocale from 'get-user-locale';

import VueApexCharts from 'vue-apexcharts'
    
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

export default {
  name: "Membersearch",
  data() {
    return {
      theme: '',
      isDarkMode: false,
      tableTheme: 'black-rhino', //polar-bear 
      seriesPartials: [],
      dailyPayouts: [],
      chartOptionsPartials: {
        dataLabels: {
          enabled: false
        },
        noData: {
          text: '...'
        },
        colors : ["#06a74d"]
        },
      chartOptionsPayouts: {
        chart: {
        height: 250,
        type: 'line'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        noData: {
          text: '...'
        },
        colors : ["#06a74d"]
        },
      showSearchResult: false,
      searchKeyword: "",
      searchData: {},
      partialsList: [],
      partialsDetailedList: [],
      roundsList: [],
      transactionsList: [],
      chartPartials: [],
      chartPayouts: [],
      winPerBlock: '',
      rewardAddressLink: "",
      showProofsLink: "#",
      sortBy: "timestamp",
      sortDesc: true,
      columns: [{
          label: this.$i18n.t('memberSearch-screen.tableColumnTimestamp'),
          field: 'timestamp',
        },
        {
          label: this.$i18n.t('memberSearch-screen.tableColumnDifficulty'),
          field: 'points',
          type: 'string',
        },
        {
          label: 'Status',
          field: 'errorcode',
          type: 'string'
        }
        ],
      columnsPayout: [{
          label: this.$i18n.t('memberSearch-screen.tableColumnDateEarned'),
          field: 'date_earned',
        },
        {
          label: this.$i18n.t('memberSearch-screen.tableColumnAmount'),
          field: 'amount',
          type: 'number',
        },
        {
          label: this.$i18n.t('memberSearch-screen.tableColumnState'),
          field: 'state',
          type: 'number',
        },
        {
          label: this.$i18n.t('memberSearch-screen.tableColumnTransactionID'),
          field: 'transaction_id',
          type: 'number',
        }
        ],
      members: []
    };
  },

  methods: {
    getInitialMemberList() {
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      this.$axios.get("members/list", { httpsAgent: agent }).then((res) => {
        this.searchData = res.data;
        this.showSearchResult = false;
      });
    },

    async performSearch() {
      let userLocale = getUserLocale();
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });

      if (this.searchKeyword != "") {
        this.$axios
          .get("v1/members/get?search=" + this.searchKeyword, {
            httpsAgent: agent,
          })
          .then((res) => {
            // change join format
            moment.locale(userLocale);

            this.searchData = res.data;
            this.showProofsLink = res.data.singleton ? res.data.singleton : "";
            
            this.rewardAddressLink = res.data.rewardAddress
              ? "https://www.chiaexplorer.com/blockchain/address/" +
                res.data.rewardAddress
              : "";
            
            this.searchData.netspace = this.getFormatedPoolBytes(res.data.netspace);

            this.searchData.joined = this.searchData.joined ? moment(res.data.joined).utc().format("YYYY-MM-DD LT") : "";
            this.searchData.left = res.data.left ? moment(res.data.left).utc().format("YYYY-MM-DD LT") : "";
            this.searchData.currentPoolFee = (this.searchData.currentPoolFee * 100).toFixed(2) + " %";
            this.searchData.currentPoolShare = this.searchData.currentPoolShare ? (res.data.currentPoolShare * 100).toFixed(4) + " %" : "";
            this.searchData.expectedEarningsToday = this.searchData.expectedEarningsToday ? "XCH " + this.searchData.expectedEarningsToday.toFixed(4) : "No data";

            this.searchData["showProof"] = true;
            this.searchData["showPayout"] = true;
            
            this.showSearchResult = res.data.singleton || false;

            this.partialsList = res.data.partials;
            if(this.partialsList){
              this.partialsList.forEach(element => {
                element.timestamp = moment(parseInt(element.timestamp * 1000)).utc().format("YYYY-MM-DD LT");
                this.chartPartials.push({
                    "x" : element.timestamp,
                    "y" : element.points
                })
              });
              this.seriesPartials = [{
                name: 'Points',
                data: this.chartPartials
              }]
            }

            this.payoutsChart = res.data.payoutsChart.slice().reverse();
            this.roundsList = res.data.payouts.earnings.slice().reverse();
            this.roundsList.forEach(element => {
              moment.locale('nl');
              element.date_earned = moment(parseInt(element.date_earned * 1000)).utc().format("YYYY-MM-DD LT");
              this.chartPayouts.push({
                "x": element.date_earned,
                "y": element.amount
              });
            });
            
            this.chartPayoutsDaily = [];
            this.payoutsChart.forEach(element => {
              moment.locale('nl');
              element.timestamp = moment(parseInt(element.timestamp * 1000)).utc().format("YYYY-MM-DD");
              this.chartPayoutsDaily.push({
                "x": element.timestamp,
                "y": element.amount
              });
            });
            this.dailyPayouts = [{
              name: "Payout",
              data: this.chartPayoutsDaily
            }]

          })
          
          this.getDetailedPartials();

      } else {
        this.showSearchResult = false;
      }
    },

    async getDetailedPartials() {
      if (Object.keys(this.$route.params).length) {
        this.searchKeyword = this.$route.params.singleton;
      }
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      this.$axios
        .get(
          "v2/partials/list?singleton=" +
            this.searchKeyword,
          {
            httpsAgent: agent,
          }
        )
        .then((res) => {
          this.partialsDetailedList = res.data;
          for (let index = 0; index < this.partialsDetailedList.length; index++) {
            moment.locale("nl");
            this.partialsDetailedList[index].timestamp = moment(parseInt(this.partialsDetailedList[index].timestamp)).utc().format("YYYY-MM-DD LTS");
            switch(this.partialsDetailedList[index].errorcode) {
              case 0:
                this.partialsDetailedList[index].errorcode = "GOOD"
                break;
              case 1:
                this.partialsDetailedList[index].errorcode = "REVERTED_SIGNAGE_POINT"
                break;
              case 2:
                this.partialsDetailedList[index].errorcode = "TOO_LATE: Make sure your proof of space lookups are fast, and network connectivity is good. Response must happen in less than 25 seconds. NAS or network farming can be an issue."
                break;
              case 3:
                this.partialsDetailedList[index].errorcode = "NOT_FOUND: Did not find signage point or EOS"
                break;
              case 4:
                this.partialsDetailedList[index].errorcode = "INVALID_PROOF: Invalid proof of space"
                break;
              case 5:
                this.partialsDetailedList[index].errorcode = "PROOF_NOT_GOOD_ENOUGH: Proof of space has required iters too high for difficulty"
                break;
              case 6:
                this.partialsDetailedList[index].errorcode = "INVALID_DIFFICULTY"
                break;
              case 7:
                this.partialsDetailedList[index].errorcode = "INVALID_SIGNATURE: Failed to verify signature for launcher_id"
                break;
              case 8:
                this.partialsDetailedList[index].errorcode = "SERVER_EXCEPTION"
                break;
              case 9:
                this.partialsDetailedList[index].errorcode = "INVALID_P2_SINGLETON_PUZZLE_HASH"
                break;
              case 10:
                this.partialsDetailedList[index].errorcode = "FARMER_NOT_KNOWN"
                break;
              case 11:
                this.partialsDetailedList[index].errorcode = "FARMER_ALREADY_KNOWN"
                break;
              case 12:
                this.partialsDetailedList[index].errorcode = "INVALID_AUTHENTICATION_TOKEN"
                break;
              case 13:
                this.partialsDetailedList[index].errorcode = "INVALID_PAYOUT_INSTRUCTIONS"
                break;
              case 14:
                this.partialsDetailedList[index].errorcode = "INVALID_SINGLETON"
                break;
              case 15:
                this.partialsDetailedList[index].errorcode = "DELAY_TIME_TOO_SHORT"
                break;
              case 16:
                this.partialsDetailedList[index].errorcode = "REQUEST_FAILED"
                break;
              case 1001:
                this.partialsDetailedList[index].errorcode = "UPGRADE_NEEDED: This partial was rejected because it was generated from a too old CHIA client. upgrade your farm to v1.3 or higher"
                break;
              default:
                this.partialsDetailedList[index].errorcode = "OTHER";
            }
          }
        }).catch(function (error) {
          console.log(error.toJSON());
        });
    },

    getFormatedPoolBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      var sizes = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat(bytes / Math.pow(k, i)).toFixed(dm) + " " + sizes[i];
    },
    toggleTheme() {
        this.theme = this.theme == 'darkMode' ? '' : 'darkMode';
        document.documentElement.setAttribute('data-theme', this.theme); 
        localStorage.setItem('theme', this.theme);
        this.updateGraphTheme(this.theme);
    },
    updateGraphTheme(theme) {
        if(theme == 'darkMode'){
            this.chartOptionsPartials = {
              dataLabels: {
                enabled: false
              },
              theme: {
                  mode: 'dark'
              },
              colors : ["#06a74d"]
            }
            this.chartOptionsPayouts = {
              dataLabels: {
                enabled: false
              },
              theme: {
                  mode: 'dark'
              },
              colors : ["#06a74d"]
            }
            this.isDarkMode = true,
            this.tableTheme = "black-rhino"
        }else{
            this.chartOptionsPartials = {
              dataLabels: {
                enabled: false
              },
              theme: {
                  mode: 'light'
              },
              colors : ["#06a74d"]
            }
            this.chartOptionsPayouts = {
              dataLabels: {
                enabled: false
              },
              theme: {
                  mode: 'light'
              },
              colors : ["#06a74d"]
            }
            this.isDarkMode = false,
            this.tableTheme = "polar-bear"
        }
    }
  },
  created() {
    if (Object.keys(this.$route.query).length) {
      this.searchKeyword = this.$route.query.singleton;
      this.performSearch();
      } 
    else {
        this.showSearchResult = false;
    }
    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
    document.documentElement.setAttribute('data-theme', localTheme);
    this.theme = localTheme;
    this.updateGraphTheme(this.theme);
    
  }
};
</script>
<style scoped>
.theme--light.v-application {
  background: transparent;
}
</style>