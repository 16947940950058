<template>
  <section class="wrapper home_wrapper">
    <div class="container">
      <div class="row row-header">
        <div class="col-sm-12">
          <toggle-button @change="toggleTheme" :value="isDarkMode" :labels="{checked: 'Light', unchecked: 'Dark'}" :color="{checked: '#989898', unchecked: '#383838'}" :width="60" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-padding">
            <h5>{{ $t('poolEarnings-screen.earningsTitle') }}</h5>
            <p>{{ $t('poolEarnings-screen.earningsDescription') }}</p>
            <div class="h1 text-center">
              <apexchart height="350" :options="chartOptions" :series="chartSeries"></apexchart>
            </div>
            <div class="graph_wrap"></div>
          </div>
        </div>
      </div>

        <div class="row row-margin-bottom">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <div class="h1">
                          <vue-good-table
                            :columns="columnsEarnings"
                            :rows="poolEarnings"
                            :sort-options="{
                              enabled: true,
                              initialSortBy: {field: 'timestamp', type: 'desc'}
                            }"
                            :theme="tableTheme"
                            :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 10,
                              position: 'bottom',
                              perPageDropdownEnabled: false,
                              perPageDropdown: [20, 50, 100],
                              dropdownAllowAll: false,
                              setCurrentPage: 1,
                              nextLabel: $t('poolEarnings-screen.tableNextLabel'),
                              prevLabel: $t('poolEarnings-screen.tablePrevLabel'),
                              rowsPerPageLabel: $t('poolEarnings-screen.tableLinesPerPagesLabel'),
                              ofLabel: $t('poolEarnings-screen.tableOfLabel'),
                              pageLabel: $t('poolEarnings-screen.tablePageLabel'), 
                              allLabel: $t('poolEarnings-screen.tableAllLabel') 
                            }"
                            >
                            <template slot="table-row" slot-scope="props">
                              <span v-if="props.column.field == 'payout_id'">
                                <router-link :to="{ name: 'PayoutList', query: { payout_id: props.row.payout_id }}">{{props.row.payout_id}}</router-link>
                              </span>
                            </template>
                          </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import https from "https";
import moment from "moment";
import VueApexCharts from 'vue-apexcharts'
    
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

export default {
  name: "Poolearnings",

  data() {
    return {
      theme: '',
      isDarkMode: false,
      tableTheme: 'black-rhino',
      seriesEarnings: [],
      //Chart props
      chartSeries: [],
      chartCategories : [],
      chartOptions : {},
      
      // Table props
      columnsEarnings: [{
        label: this.$i18n.t('poolEarnings-screen.tableColumnTimestamp'),
        field: 'timestamp'
        },
        {
          label: this.$i18n.t('poolEarnings-screen.tableColumnBlock'),
          field: 'blocks',
          type: 'number',
        },
        {
          label: this.$i18n.t('poolEarnings-screen.tableColumnAmount'),
          field: 'amount',
          type: 'number',
        },
        {
          label: this.$i18n.t('poolEarnings-screen.tableColumnPayoutID'),
          field: 'payout_id',
          type: 'number',
        }
      ],
      poolEarnings: [],
    };
  },

  methods: {
    async getPoolEarnings() {
      const agent = new https.Agent({
          rejectUnauthorized: false,
      });
      this.$axios.get("v2/earnings/list", {httpsAgent: agent})
      .then( res => {
        let poolBlocksTempEarnings = [];
        let poolXCHTempEarnings = [];
        moment.locale('nl');
        this.poolEarnings = res.data.payouts;
        this.poolEarnings.forEach(element => {
          element.timestamp = moment(parseInt(element.timestamp * 1000)).utc().format("YYYY-MM-DD LT");
        });

        this.poolEarningsChart = res.data.chart;
        
        // Loading data in the chart array
        this.poolEarningsChart.slice().reverse().forEach(element => {
            console.log(element.timestamp);
            element.timestamp = moment(parseInt(element.timestamp * 1000)).utc().format("YYYY-MM-DD");
            console.log(element.timestamp);
            poolBlocksTempEarnings.push({
              "x": element.timestamp,
              "y": element.blocks
              });
            poolXCHTempEarnings.push({
              "x": element.timestamp,
              "y": element.amount
            });
        });

        this.chartSeries = [
          {
            name: this.$i18n.t('poolEarnings-screen.blocksTitle'),
            type: 'column',
            data: poolBlocksTempEarnings
          },
          {
            name: this.$i18n.t('poolEarnings-screen.earningsTitle'),
            type: 'line',
            data: poolXCHTempEarnings
          }];
      }); 
    },
    getFormatedPoolBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      var sizes = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat(bytes / Math.pow(k, i)).toFixed(dm) + " " + sizes[i];
    },
    toggleTheme() {
      this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
      document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
      localStorage.setItem('theme', this.theme); // stores theme value on local storage
      this.updateGraphTheme(this.theme);
    },
    updateGraphTheme(theme) {
      if(theme == 'darkMode'){
        this.chartOptions = {
          chart: {
          height: 400,
          type: "line",
          stacked: false
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#5acc59', '#06a74d'],
        stroke: {
          width: [4, 4]
        },
        plotOptions: {
          bar: {
            columnWidth: "20%"
          }
        },
        xaxis: {
          categories: ''
        },
        yaxis: [
          {
            seriesName: this.$i18n.t('poolEarnings-screen.blocksTitle'),
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: this.$i18n.t('poolEarnings-screen.blocksTitle')
            }
          },
          {
            opposite: true,
            seriesName: this.$i18n.t('poolEarnings-screen.earningsTitle'),
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: this.$i18n.t('poolEarnings-screen.earningsTitle')
            }
          }
        ],
        legend: {
          horizontalAlign: "left",
          offsetX: 40
        },
          theme: {
              mode: 'dark'
          }
        }
        this.isDarkMode = true,
        this.tableTheme = "black-rhino"
      }
      else{
        this.chartOptions = {
          chart: {
          height: 400,
          type: "line",
          stacked: false
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#5acc59', '#06a74d'],
        stroke: {
          width: [4, 4]
        },
        plotOptions: {
          bar: {
            columnWidth: "20%"
          }
        },
        xaxis: {
          categories: this.chartCategories
        },
        yaxis: [
          {
            seriesName: 'Won Blocks',
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: "Won Blocks"
            }
          },
          {
            opposite: true,
            seriesName: 'Earnings',
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: "Earnings"
            }
          }
        ],
        legend: {
          horizontalAlign: "left",
          offsetX: 40
        },
          theme: {
              mode: 'light'
          }
        }
        this.isDarkMode = false,
        this.tableTheme = "polar-bear"
      }
    }
  },

  created() {    
    // function call to get pool stats
    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
    document.documentElement.setAttribute('data-theme', localTheme);
    this.theme = localTheme;
    this.getPoolEarnings();
    this.updateGraphTheme(this.theme);
  }
};
</script>

<style lang="scss" scoped>
    .theme--light.v-application {
      background: transparent;
    }
</style>