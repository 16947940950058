import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Layout from "./layouts/Layout"
import axios from 'axios'
import VueAnalytics from 'vue-analytics';
import VueApexCharts from 'vue-apexcharts';
import JsonCSV from 'vue-json-csv';

// Import CSS for site and components
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';
import 'vue-good-table/dist/vue-good-table.css'
import 'sweetalert2/dist/sweetalert2.min.css';

// Import plugins 
import ToggleButton from 'vue-js-toggle-button'
import Vuetify from 'vuetify/lib'
import VueGoodTablePlugin from 'vue-good-table'
import i18n from './i18n'
import MapChart from 'vue-map-chart'
import VueQriously from 'vue-qriously'
import VueSweetalert2 from 'vue-sweetalert2';
const optionsSweetAlert = {
  confirmButtonColor: '#06a74d',
  cancelButtonColor: '#ff7674',
};

Vue.component('layout-layout',Layout);
Vue.component('MapChart', MapChart);
Vue.component('downloadCsv', JsonCSV);
Vue.use(VueQriously)
Vue.use(Vuetify);
Vue.use(VueGoodTablePlugin);
Vue.use(VueApexCharts);
Vue.use(ToggleButton);
Vue.use(VueSweetalert2, optionsSweetAlert);
Vue.use(VueAnalytics, {
    id: 'G-GK3BN2GZM3',
    router
  });

//Configuration import
import configJson from './configs/config.json'

// Vue Configuration
Vue.prototype.$configJson = configJson;
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$axiosExternal = axios

const apiURI = configJson.API_URL;
axios.defaults.baseURL = apiURI;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'


new Vue({
    vuetify,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app');