<template>
  <section class="wrapper home_wrapper">
    <div class="container">

      <div class="row row-header">
        <div class="col-sm-6">
          <toggle-button @change="toggleTheme" :value="isDarkMode" :labels="{checked: 'Light', unchecked: 'Dark'}" :color="{checked: '#989898', unchecked: '#383838'}" :width="60" />
        </div>
        <div class="col-sm-6 align-right">
          <a href="/settings" class="settingLink">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="30" width="30"
              viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <g>
              <g>
                <path d="M328.844,368.733c-5.383-3.235-10.908-6.487-16.433-9.675c1.299-11.07,1.302-22.268,0.006-33.316
                  c5.546-3.2,11.068-6.45,16.425-9.671c9.02-5.42,12.78-16.856,8.744-26.603c0,0,0,0-0.001-0.001l-11.771-28.418
                  c-4.037-9.744-14.777-15.175-24.993-12.63c-6.071,1.511-12.274,3.118-18.455,4.776c-6.901-8.732-14.82-16.649-23.563-23.554
                  c1.658-6.182,3.266-12.388,4.778-18.461c2.545-10.211-2.885-20.955-12.629-24.992l-28.418-11.771
                  c-9.744-4.038-21.183-0.281-26.605,8.741c-3.229,5.374-6.482,10.899-9.674,16.433c-11.067-1.299-22.263-1.302-33.316-0.007
                  c-3.202-5.551-6.453-11.074-9.67-16.426c-5.424-9.02-16.86-12.778-26.605-8.741l-28.418,11.771
                  c-9.742,4.036-15.174,14.781-12.629,24.992c1.515,6.085,3.121,12.289,4.776,18.455c-8.731,6.899-16.649,14.82-23.553,23.563
                  c-6.196-1.663-12.402-3.269-18.463-4.779c-10.214-2.547-20.956,2.888-24.993,12.631L1.615,289.467
                  c-4.035,9.745-0.276,21.182,8.742,26.602c5.375,3.23,10.9,6.482,16.433,9.675c-1.298,11.066-1.301,22.262-0.006,33.316
                  c-5.538,3.195-11.06,6.446-16.429,9.672c-9.018,5.421-12.777,16.857-8.741,26.603l11.772,28.418
                  c4.036,9.743,14.775,15.176,24.993,12.63c6.064-1.51,12.268-3.116,18.453-4.776c6.901,8.731,14.82,16.647,23.563,23.553
                  c-1.66,6.189-3.267,12.395-4.778,18.461c-2.545,10.211,2.885,20.956,12.629,24.992l28.419,11.772
                  c2.64,1.093,5.403,1.615,8.137,1.615c7.362,0,14.514-3.779,18.471-10.355c3.205-5.333,6.456-10.856,9.674-16.432
                  c11.065,1.297,22.264,1.299,33.316,0.006c3.179,5.512,6.43,11.034,9.67,16.427c5.422,9.02,16.858,12.777,26.605,8.741
                  l28.418-11.771c9.742-4.036,15.174-14.781,12.629-24.992c-1.503-6.033-3.109-12.237-4.776-18.455
                  c8.729-6.899,16.648-14.819,23.554-23.562c6.178,1.657,12.384,3.263,18.46,4.777c10.211,2.547,20.956-2.884,24.993-12.63
                  l11.771-28.419C341.621,385.59,337.862,374.153,328.844,368.733z M305.116,413.834c-5.837-1.462-11.787-3.008-17.703-4.602
                  c-8.376-2.253-17.215,0.692-22.519,7.507c-6.096,7.83-13.151,14.886-20.968,20.973c-6.807,5.302-9.748,14.138-7.493,22.509
                  c1.603,5.954,3.148,11.9,4.601,17.696l-26.122,10.819c-3.107-5.184-6.218-10.481-9.259-15.765
                  c-4.327-7.514-12.655-11.678-21.216-10.618c-9.831,1.221-19.81,1.22-29.654-0.005c-8.585-1.066-16.906,3.098-21.232,10.614
                  c-3.078,5.344-6.19,10.644-9.263,15.773l-26.122-10.82c1.461-5.827,3.007-11.776,4.603-17.701
                  c2.255-8.375-0.692-17.215-7.507-22.52c-7.83-6.095-14.885-13.149-20.973-20.965c-5.3-6.809-14.136-9.749-22.51-7.495
                  c-5.923,1.595-11.87,3.141-17.695,4.601l-10.82-26.121c5.16-3.094,10.459-6.205,15.765-9.26
                  c7.515-4.327,11.683-12.653,10.619-21.215c-1.223-9.832-1.22-19.81,0.003-29.655c1.068-8.57-3.097-16.905-10.616-21.233
                  c-5.304-3.054-10.604-6.166-15.772-9.265l10.82-26.121c5.82,1.459,11.768,3.005,17.702,4.602
                  c8.372,2.256,17.212-0.692,22.52-7.507c6.095-7.83,13.15-14.886,20.965-20.972c6.808-5.301,9.749-14.138,7.494-22.512
                  c-1.59-5.904-3.136-11.85-4.601-17.694l26.122-10.819c3.084,5.146,6.196,10.444,9.259,15.765
                  c4.328,7.515,12.663,11.689,21.216,10.618c9.827-1.223,19.804-1.223,29.657,0.005c8.575,1.068,16.905-3.102,21.231-10.617
                  c3.053-5.304,6.166-10.604,9.262-15.771l26.122,10.819c-1.462,5.833-3.008,11.782-4.603,17.703
                  c-2.255,8.374,0.692,17.214,7.507,22.519c7.829,6.095,14.885,13.149,20.973,20.965c5.3,6.807,14.133,9.748,22.511,7.494
                  c5.919-1.594,11.865-3.14,17.694-4.601l10.819,26.121c-5.153,3.089-10.451,6.2-15.765,9.26
                  c-7.515,4.327-11.684,12.654-10.619,21.216c1.223,9.827,1.221,19.806-0.005,29.657c-1.066,8.571,3.101,16.904,10.617,21.231
                  c5.296,3.049,10.596,6.161,15.772,9.263L305.116,413.834z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M241.762,312.511c0,0,0,0-0.001-0.001c-16.48-39.789-62.256-58.752-102.051-42.271
                  C99.922,286.721,80.958,332.501,97.44,372.29c7.983,19.276,22.997,34.287,42.271,42.272c9.637,3.991,19.763,5.987,29.889,5.987
                  s20.252-1.996,29.891-5.987C239.279,398.08,258.242,352.301,241.762,312.511z M190.72,393.388c-13.618,5.641-28.617,5.64-42.239,0
                  c-13.618-5.641-24.225-16.249-29.867-29.868c-11.647-28.114,1.753-60.462,29.868-72.107c6.885-2.852,14.021-4.202,21.047-4.202
                  c21.667,0,42.265,12.84,51.06,34.07C232.234,349.395,218.834,381.742,190.72,393.388z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M496.553,100.936c-3.956-0.592-8.019-1.167-12.111-1.713c-2.062-6.857-4.816-13.505-8.214-19.819
                  c2.504-3.275,4.972-6.556,7.357-9.779c5.295-7.161,4.529-17.317-1.78-23.623l-15.808-15.806
                  c-6.309-6.31-16.465-7.075-23.624-1.778c-3.234,2.393-6.514,4.86-9.779,7.355c-6.313-3.398-12.96-6.152-19.819-8.215
                  c-0.547-4.092-1.12-8.155-1.712-12.11C409.746,6.642,402.024,0,393.102,0h-22.356c-8.922,0-16.644,6.642-17.961,15.447
                  c-0.591,3.953-1.165,8.017-1.712,12.11c-6.859,2.063-13.507,4.817-19.819,8.215c-3.263-2.496-6.545-4.964-9.778-7.355
                  c-7.161-5.297-17.317-4.532-23.624,1.777l-15.806,15.805c-6.31,6.308-7.077,16.465-1.78,23.627
                  c2.383,3.222,4.851,6.504,7.354,9.778c-3.396,6.313-6.151,12.96-8.214,19.819c-4.091,0.547-8.155,1.121-12.11,1.713
                  c-8.807,1.318-15.449,9.039-15.449,17.962v22.355c0,8.922,6.64,16.644,15.447,17.962c3.956,0.592,8.019,1.167,12.111,1.713
                  c2.063,6.858,4.817,13.505,8.214,19.819c-2.501,3.272-4.969,6.552-7.357,9.779c-5.294,7.159-4.529,17.317,1.78,23.622
                  l15.808,15.808c6.308,6.31,16.464,7.074,23.624,1.778c3.234-2.391,6.514-4.86,9.778-7.354c6.315,3.398,12.963,6.152,19.82,8.214
                  c0.545,4.091,1.12,8.155,1.712,12.111c1.318,8.806,9.04,15.448,17.962,15.448h22.356c8.922,0,16.644-6.64,17.961-15.449
                  c0.591-3.956,1.165-8.019,1.712-12.111c6.858-2.061,13.505-4.817,19.819-8.215c3.263,2.496,6.545,4.964,9.778,7.356
                  c7.159,5.297,17.317,4.532,23.624-1.777l15.808-15.806c6.309-6.308,7.075-16.465,1.778-23.626
                  c-2.386-3.226-4.854-6.508-7.354-9.777c3.396-6.313,6.151-12.959,8.214-19.819c4.091-0.547,8.155-1.121,12.11-1.713
                  c8.807-1.318,15.449-9.039,15.449-17.962v-22.355C512,109.976,505.359,102.254,496.553,100.936z M490.228,138.142
                  c-3.592,0.521-7.257,1.027-10.934,1.506c-7.273,0.949-13.227,6.111-15.165,13.149c-1.857,6.739-4.552,13.242-8.009,19.328
                  c-3.611,6.353-3.054,14.215,1.419,20.032c2.255,2.932,4.492,5.883,6.669,8.799l-11.404,11.405
                  c-2.925-2.183-5.876-4.419-8.802-6.669c-5.814-4.475-13.678-5.028-20.032-1.421c-6.087,3.458-12.59,6.153-19.325,8.01
                  c-7.042,1.94-12.204,7.894-13.15,15.165c-0.479,3.677-0.984,7.343-1.506,10.935h-16.13c-0.521-3.592-1.026-7.257-1.506-10.931
                  c-0.949-7.274-6.11-13.228-13.15-15.168c-6.737-1.856-13.238-4.551-19.327-8.009c-6.354-3.61-14.218-3.055-20.031,1.419
                  c-2.927,2.251-5.876,4.486-8.803,6.669l-11.404-11.405c2.177-2.919,4.413-5.868,6.671-8.803c4.47-5.815,5.027-13.677,1.417-20.03
                  c-3.457-6.086-6.152-12.59-8.009-19.327c-1.939-7.04-7.892-12.201-15.165-13.15c-3.677-0.48-7.343-0.984-10.935-1.507v-16.128
                  c3.592-0.521,7.257-1.027,10.934-1.506c7.273-0.949,13.227-6.111,15.165-13.149c1.857-6.738,4.551-13.241,8.009-19.328
                  c3.611-6.353,3.054-14.216-1.417-20.031c-2.259-2.936-4.495-5.886-6.67-8.8l11.404-11.405c2.925,2.183,5.875,4.419,8.8,6.668
                  c5.812,4.475,13.675,5.033,20.034,1.422c6.085-3.457,12.589-6.152,19.325-8.01c7.042-1.94,12.204-7.894,13.15-15.165
                  c0.48-3.677,0.986-7.342,1.507-10.934h16.13c0.521,3.591,1.026,7.256,1.505,10.931c0.949,7.274,6.111,13.228,13.15,15.167
                  c6.738,1.857,13.242,4.553,19.328,8.01c6.355,3.611,14.218,3.05,20.03-1.42c2.927-2.25,5.876-4.486,8.803-6.669l11.404,11.405
                  c-2.175,2.915-4.411,5.866-6.67,8.803c-4.471,5.814-5.027,13.676-1.419,20.029c3.457,6.088,6.152,12.592,8.009,19.328
                  c1.939,7.04,7.892,12.201,15.165,13.15c3.677,0.48,7.343,0.985,10.935,1.507V138.142z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M381.924,83.675c-25.585,0-46.401,20.815-46.401,46.401c0,25.587,20.815,46.402,46.401,46.402
                  s46.401-20.815,46.401-46.402C428.325,104.49,407.51,83.675,381.924,83.675z M381.924,155.851
                  c-14.211,0-25.775-11.563-25.775-25.776c0-14.213,11.563-25.775,25.775-25.775c14.212,0,25.774,11.563,25.775,25.775
                  C407.699,144.288,396.136,155.851,381.924,155.851z"/>
              </g>
            </g>
            </svg>
          </a>
        </div>
      </div>

      <div class="row row-margin-bottom" v-if="!nickname">
        <div class="col-md-12 ">
            <div class="searchresult box">
              <div class="sr_title">
                <h2> {{ $t('myFarm-screen.noTokenTitle') }}</h2>
                <h5 style='margin-top: 20px'> {{ $t('myFarm-screen.noTokenDescription') }}</h5>
                <h4 style='color:black; margin-top: 30px'> {{ $t('myFarm-screen.noTokenExplanationCLI') }}</h4>
                <h5 style='color:blue; margin-top: 10px'> {{ $t('myFarm-screen.noTokenExplanationCLI2') }}</h5>
                <h5 style='margin-top: 10px'> {{ $t('myFarm-screen.noTokenExplanationCLI3') }}</h5>
                <h4 style='color:black; margin-top: 30px'> {{ $t('myFarm-screen.noTokenExplanationGUI') }}</h4>
                <h5 style='margin-top: 10px'> {{ $t('myFarm-screen.noTokenExplanationGUI2') }}</h5>
                <img src="@/assets/images/getLoginLink.png" style='max-width:500px; margin: 20px; object-fit: contain;' height="100%" width="100%" />
                <h5 style='margin-top: 10px'> {{ $t('myFarm-screen.noTokenExplanationGUI3') }}</h5>
              </div>
            </div>
        </div>
      </div>

      <div class="row" v-if="nickname">
        <div class="col-md-12">
            <div class="searchresult box">
              <div class="sr_title">
                <h4> {{nickname}} {{ $t('myFarm-screen.farmLabel') }} </h4>
                <h5>{{ $t('myFarm-screen.launcherIDLabel') }} {{launcher_id}}</h5>
              </div>
            </div>
        </div>
      </div>

      <div class="row" v-if="nickname">
          <div class="col-sm-8">
            <div class="graphBox box" style="height: 100%">
              <b>{{ $t('myFarm-screen.launcherIDLabel') }} </b> {{searchData.singleton}} <br/>
              <b>{{ $t('myFarm-screen.poolContractAddressLabel') }} </b> {{searchData.p2_singleton_address}}<br/>
              <b>{{ $t('myFarm-screen.rewardAddressLabel') }}</b> {{ searchData.rewardAddress }}<br/>
              <b>{{ $t('myFarm-screen.joindedLabel') }}</b> {{searchData.joined}} <br/>
              <b>{{ $t('myFarm-screen.partialTargetLabel') }}</b> {{searchData.partials_target}} <br/>
              <b>{{ $t('myFarm-screen.infosLabel') }}</b> {{searchData.info}} <br/>
              <div v-if="searchData.hasTooLowClientVersion">
                <br/><h4 style="color: red" >Warning! you are running a too old CHIA client version &lt; v1.3 </h4>
                <b> Join to our <a target="_blank" href="https://discord.com/invite/AzK3Z3qbek">discord</a> for more information or if you have questions</b><br/>
                <b> Chia Network official <a target="_blank" href="https://www.chia.net/2022/03/04/divided-we-fork.en.html">announcement</a> here</b><br/>
                <b> Download the <a target="_blank" href="https://www.chia.net/download/">upgrade</a> here</b>
              </div>
            </div>
          </div>
          <div class="col-sm-4" style="height: 100%">
            <div class="graphBox box text-center">
              <h5>{{ $t('myFarm-screen.applicationAccessCode') }}</h5>
              <qriously :value="appQRCode" :size="110" />
              <h6>{{ $t('myFarm-screen.applicationAccessCode2') }}</h6>
              <h6>{{ $t('myFarm-screen.applicationAccessCode3') }}</h6>
              <h6>{{ $t('myFarm-screen.applicationAccessCode4') }}</h6>

            </div>
          </div>      
      </div>

      <div class="row row-margin-bottom" v-if="nickname">
                <div class="col-sm-3">
                    <div class="graphBox box">
                        <h5>{{ $t('myFarm-screen.totalPayoutTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.totalPayoutDescription') }}</p>
                        <div class="h1 text-center capacity_bytes">{{ searchData.totalPayout }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="graphBox box">
                        <h5>{{ $t('myFarm-screen.unpaidBalanceTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.unpaidBalanceDescription') }}</p>
                        <div class="h1 text-center">{{ searchData.unpaidBalance }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="graphBox box">
                        <h5>{{ $t('myFarm-screen.currentPoolFeeTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.currentPoolFeeDescription') }}</p>
                        <div class="h1 text-center last_reward">{{ searchData.currentPoolFee }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
               <div class="col-sm-3">
                    <div class="graphBox box">
                        <h5>{{ $t('myFarm-screen.winPerBlockTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.winPerBlockDescription') }}</p>
                        <div class="h1 text-center">{{ winPerBlock }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
        </div>
        <div class="row row-margin-bottom" v-if="nickname">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <h5>{{ $t('myFarm-screen.detailsTransactionsTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.detailsTransactionsTitle') }}</p>
                        <div class="h1">
                          <vue-good-table
                            :columns="columnsTransactions"
                            :rows="reportTransactionsList"
                            :sort-options="{
                              enabled: true,
                              initialSortBy: {field: 'timestamp', type: 'desc'}
                            }"
                            :theme="tableTheme"
                            :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 5,
                              position: 'bottom',
                              perPageDropdownEnabled: false,
                              perPageDropdown: [20, 50, 100],
                              dropdownAllowAll: false,
                              setCurrentPage: 1,
                              nextLabel: $t('myFarm-screen.tableNextLabel'),
                              prevLabel: $t('myFarm-screen.tablePrevLabel'),
                              rowsPerPageLabel: $t('myFarm-screen.tableLinesPerPagesLabel'),
                              ofLabel: $t('myFarm-screen.tableOfLabel'),
                              pageLabel: $t('myFarm-screen.tablePageLabel'), 
                              allLabel: $t('myFarm-screen.tableAllLabel') 
                            }">
                            <template slot="table-row" slot-scope="props"> 
                              <span v-if="props.column.field == 'transaction_id'">
                                <router-link
                                  :to="{
                                    name: 'TransactionList',
                                    params: { transaction_id: props.row.transaction_id },
                                  }"
                                  >{{ props.row.transaction_id }}</router-link>
                              </span>
                              <span v-else-if="props.column.field == 'payout_address'">
                                <router-link
                                  :to="{ path:'//blockchain.chiaexplorer.com/blockchain/address/' + props.row.payout_address  }"
                                  target="_blank"
                                  >{{ props.row.payout_address }}</router-link>
                              </span>
                              <span v-else>
                                {{props.formattedRow[props.column.field]}}
                              </span>
                            </template>
                          </vue-good-table>
                          <div class="align-right">
                            <download-csv
                                :data = "reportTransactionsList"
                                class = "btn btn-default"
                                name = "ExportTransactions.csv">
                                Download Transaction Report
                                <!-- <img src="download_icon.png"> -->
                            </download-csv>
                          </div>
                        </div>
                    </div>
                </div>
        </div>
        
        <div class="row row-margin-bottom" v-if="nickname">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <h5>{{ $t('myFarm-screen.payoutsTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.payoutsDescription') }}</p>
                        <div class="h1 text-center">
                            <apexchart ref="chartPayouts" type="line" height="250" :options="chartOptionsPayouts" :series="dailyPayouts"></apexchart>
                        </div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
        </div>
        
        <div class="row row-margin-bottom" v-if="nickname">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <h5>{{ $t('myFarm-screen.detailsPayoutsTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.detailsPayoutsTitle') }}</p>
                        <div class="h1">
                          <vue-good-table
                            :columns="columnsPayout"
                            :rows="roundsList"
                            :sort-options="{
                              enabled: true,
                              initialSortBy: {field: 'date_earned', type: 'desc'}
                            }"
                            :theme="tableTheme"
                            :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 5,
                              position: 'bottom',
                              perPageDropdownEnabled: false,
                              perPageDropdown: [20, 50, 100],
                              dropdownAllowAll: false,
                              setCurrentPage: 1,
                              nextLabel: $t('myFarm-screen.tableNextLabel'),
                              prevLabel: $t('myFarm-screen.tablePrevLabel'),
                              rowsPerPageLabel: $t('myFarm-screen.tableLinesPerPagesLabel'),
                              ofLabel: $t('myFarm-screen.tableOfLabel'),
                              pageLabel: $t('myFarm-screen.tablePageLabel'), 
                              allLabel: $t('myFarm-screen.tableAllLabel') 
                            }">
                            <template slot="table-row" slot-scope="props"> 
                              <span v-if="props.column.field == 'transaction_id'">
                                <router-link
                                  :to="{
                                    name: 'TransactionList',
                                    params: { transaction_id: props.row.transaction_id },
                                  }"
                                  >{{ props.row.transaction_id }}</router-link>
                              </span>
                              <span v-else-if="props.column.field == 'date_earned'">
                                <router-link
                                  :to="{ name: 'PayoutList', query: { payout_id: props.row.payout_id }}"
                                  >{{ props.row.date_earned }}</router-link>
                              </span>
                              <span v-else>
                                {{props.formattedRow[props.column.field]}}
                              </span>
                            </template>
                          </vue-good-table>
                        </div>
                    </div>
                </div>
        </div>

        <div class="row row-margin-bottom" v-if="nickname">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <h5>{{ $t('myFarm-screen.earningsReportTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.earningsReportDescription') }} 
                          <a href='/settings'>{{this.currency.toString().toUpperCase()}}</a>
                        </p>
                        <div class="h1">
                          <vue-good-table
                            :columns="columnsReportEarnings"
                            :rows="reportEarningsList"
                            :sort-options="{
                              enabled: true,
                              initialSortBy: {field: 'timestamp', type: 'desc'}
                            }"
                            :theme="tableTheme"
                            :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 5,
                              position: 'bottom',
                              perPageDropdownEnabled: false,
                              perPageDropdown: [20, 50, 100],
                              dropdownAllowAll: false,
                              setCurrentPage: 1,
                              nextLabel: $t('myFarm-screen.tableNextLabel'),
                              prevLabel: $t('myFarm-screen.tablePrevLabel'),
                              rowsPerPageLabel: $t('myFarm-screen.tableLinesPerPagesLabel'),
                              ofLabel: $t('myFarm-screen.tableOfLabel'),
                              pageLabel: $t('myFarm-screen.tablePageLabel'), 
                              allLabel: $t('myFarm-screen.tableAllLabel') 
                            }">
                          </vue-good-table>
                          <div class="align-right">
                            <download-csv
                                :data = "reportEarningsList"
                                class = "btn btn-default"
                                name = "ExportEarnings.csv">
                                Download Report
                                <!-- <img src="download_icon.png"> -->
                            </download-csv>
                          </div>
                        </div>
                    </div>
                </div>
        </div>
        
        <div class="row" v-if="nickname">
                <div class="col-sm-3">
                    <div class="graphBox box">
                        <h5>{{ $t('myFarm-screen.farmerNetspaceTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.farmerNetspaceDescription') }}</p>
                        <div class="h1 text-center capacity_bytes">{{ searchData.netspace }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                    <div class="graphBox box box-margin">
                        <h5>{{ $t('myFarm-screen.currentPoolShareTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.currentPoolShareDescription') }}</p>
                        <div class="h1 text-center">{{ searchData.currentPoolShare }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-9">
                    <div class="box box-padding">
                        <div class="h1 text-center">
                            <h5>{{ $t('myFarm-screen.farmerCapacityEvolutionTitle') }}</h5>
                            <apexchart ref="chartFarmerGrowth" type="area" height="274" :options="chartOptionsNetspace" :series="seriesFarmerCapacity"></apexchart>
                        </div>
                    </div>
                </div>
        </div>

            <div class="row" v-if="nickname">
                <div class="col-sm-9">
                    <div class="box box-padding">
                        <h5>{{ $t('myFarm-screen.wonPointsTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.wonPointsDescription') }}</p>
                        <div class="h1 text-center">
                            <apexchart ref="chartPartials" type="bar" height="240" :options="chartOptionsPoints" :series="seriesPoints"></apexchart>
                        </div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="graphBox box">
                        <h5>{{ $t('myFarm-screen.currentPointsTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.currentPointsTitle') }}</p>
                        <div class="h1 text-center last_reward">{{ searchData.points }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                    <div class="graphBox box box-margin">
                        <h5>{{ $t('myFarm-screen.farmerDifficultyTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.farmerDifficultyDescription') }}</p>
                        <div class="h1 text-center">{{ searchData.farmerDifficulty }}</div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
            </div>
        
        <div class="row row-margin-bottom" v-if="nickname">
                <div class="col-sm-6">
                    <div class="box box-padding">
                        <h5>{{ $t('myFarm-screen.submittedPartialsTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.submittedPartialsDescription') }}</p>
                        <div class="h1 text-center">
                            <apexchart ref="chartPartials" type="bar" height="250" :options="chartOptionsPartials" :series="seriesPartials"></apexchart>
                        </div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="box box-padding">
                        <h5>{{ $t('myFarm-screen.partialsDistribution') }}</h5>
                        <p>{{ $t('myFarm-screen.detailsSubmittedPartialsDescription') }}</p>
                        <div class="h1 text-center">
                            <apexchart ref="chartPartialsDonut" type="donut" height="262" :options="chartOptionsPartialsDonut" :series="seriesPartialsDonut"></apexchart>
                        </div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
        </div>
        
        <div class="row row-margin-bottom" v-if="nickname">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <h5>{{ $t('myFarm-screen.detailsSubmittedPartialsTitle') }}</h5>
                        <p>{{ $t('myFarm-screen.detailsSubmittedPartialsDescription') }}</p>
                        <div class="h1">
                          <vue-good-table
                            :columns="columns"
                            :rows="partialsDetailedList"
                            :sort-options="{
                              enabled: true,
                              initialSortBy: {field: 'timestamp', type: 'desc'}
                            }"
                            :theme="tableTheme"
                            :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 10,
                              position: 'bottom',
                              perPageDropdownEnabled: false,
                              perPageDropdown: [20, 50, 100],
                              dropdownAllowAll: false,
                              setCurrentPage: 1,
                              nextLabel: $t('myFarm-screen.tableNextLabel'),
                              prevLabel: $t('myFarm-screen.tablePrevLabel'),
                              rowsPerPageLabel: $t('myFarm-screen.tableLinesPerPagesLabel'),
                              ofLabel: $t('myFarm-screen.tableOfLabel'),
                              pageLabel: $t('myFarm-screen.tablePageLabel'), 
                              allLabel: $t('myFarm-screen.tableAllLabel')
                            }">
                          </vue-good-table>
                        </div>
                    </div>
                </div>
        </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import https from "https";
import moment from "moment";
import getUserLocale from 'get-user-locale';
import Enumerable from 'linq';
import VueApexCharts from 'vue-apexcharts'
    
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

export default {
  name: "Myfarm",
  data() {
    return {
      countDown: 60,
      theme: '',
      autoRefresh: '',
      isDarkMode: false,
      tableTheme: 'black-rhino', //polar-bear 
      nickname: '',
      launcher_id: '',
      token: '',
      appQRCode: '',
      seriesFarmerCapacity: [],
      seriesPoints: [],
      seriesPartials: [],
      seriesPartialsDonut: [],
      seriesPayouts: [],
      dailyPayouts: [],
      chartOptionsPartials: {
        dataLabels: { enabled: false },
        chart: { type: 'bar' },
        stacked: true,
        noData: { text: '...'}
      },
      chartOptionsPoints: {
        dataLabels: { enabled: false },
        noData: { text: '...' }
      },
      chartOptionsPayouts: {
        chart: { height: 250, type: 'line' },
        dataLabels: { enabled: false },
        stroke: { curve: 'smooth' },
        noData: { text: '...' }
      },
      chartOptionsPartialsDonut: {
        chart: { height: 300, type: 'donut' },
        plotOptions: { pie: { customScale: 0.8 }},
        labels: ['GOOD', 'INVALID_SIGNATURE', 'TOO_LATE', 'PROOF_NOT_GOOD', 'NOT_FOUND'],
        dataLabels: { enabled: true },
        noData: { text: '...' }
      },
      chartOptionsNetspace: {
        chart: { height: 250, type: 'area' },
        dataLabels: { enabled: true },
        stroke: { curve: 'smooth' },
        noData: { text: '...' },
        colors : ["#06a74d"]
      },
      showSearchResult: false,
      searchKeyword: '',
      searchData: {},
      partialsList: [],
      partialsDetailedList: [],
      currency: null,
      reportEarningsList: [],
      reportTransactionsList: [],
      jsonReportEarningsData: '',
      roundsList: [],
      transactionList: [],
      chartPoints: [],
      chartPartials: [],
      chartPartialsError: [],
      chartPayouts: [],
      farmerCapacityChart: [],
      winPerBlock: '',
      rewardAddressLink: "",
      showProofsLink: "#",
      sortBy: "timestamp",
      sortDesc: true,
      columns: [
        { label: this.$i18n.t('myFarm-screen.tableColumnTimestamp'), field: 'timestamp' },
        { label: this.$i18n.t('myFarm-screen.tableColumnDifficulty'), field: 'points', type: 'string' },
        { label: 'Status', field: 'errorcode', type: 'string' }
      ],
      columnsPayout: [
        { label: this.$i18n.t('myFarm-screen.tableColumnDateEarned'), field: 'date_earned'},
        { label: this.$i18n.t('myFarm-screen.tableColumnAmount'), field: 'amount', type: 'number'},
        { label: this.$i18n.t('myFarm-screen.tableColumnState'), field: 'state', type: 'number'},
        { label: this.$i18n.t('myFarm-screen.tableColumnTransactionID'), field: 'transaction_id', type: 'string'}
      ],
      columnsTransactions: [
        { label: this.$i18n.t('myFarm-screen.tableColumnTimestamp'), field: 'timestamp'},
        { label: this.$i18n.t('myFarm-screen.tableColumnType'), field: 'type', type: 'string'},
        { label: this.$i18n.t('myFarm-screen.tableColumnAmount'), field: 'Amount_XCH', type: 'number'},
        { label: this.$i18n.t('myFarm-screen.tableColumnAddress'), field: 'payout_address', type: 'string'}
      ],
      columnsReportEarnings: [
        { label: this.$i18n.t('myFarm-screen.tableColumnDateEarned'), field: 'timestamp'},
        { label: this.$i18n.t('myFarm-screen.tableColumnAmountXCH'), field: 'Amount_XCH', type: 'number'},
        { label: this.$i18n.t('myFarm-screen.tableColumnCurrencyAmount'), field: 'Amount', type: 'number'}
      ],
      members: []
    };
  },
  
  methods: {
    async performSearch() {
      let userLocale = getUserLocale();
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });

      this.$axios
        .get("v1/members/get?search=" + this.launcher_id, {
          httpsAgent: agent,
        })
        .then((res) => {
          moment.locale(userLocale);
          this.searchData = res.data;
          this.showProofsLink = res.data.singleton ? res.data.singleton : "";
          this.rewardAddressLink = res.data.rewardAddress ? "https://www.chiaexplorer.com/blockchain/address/" + res.data.rewardAddress : "";
          this.searchData.netspace = this.getFormatedPoolBytes(res.data.netspace);
          this.searchData.joined = moment(res.data.joined).utc().format("YYYY-MM-DD LT");
          this.searchData.left = res.data.left ? moment(res.data.left).utc().format("YYYY-MM-DD LT") : "";
          this.searchData.currentPoolFee = (res.data.currentPoolFee * 100).toFixed(2) + " %";
          this.searchData.currentPoolShare = (res.data.currentPoolShare * 100).toFixed(4) + " %";
          this.winPerBlock = (1.75 * parseFloat(res.data.currentPoolShare) / 100).toFixed(4);
          this.searchData["showProof"] = true;
          this.searchData["showPayout"] = true;
          this.showSearchResult = res.data.singleton || false;
          this.searchData.capacityChart.forEach(element => {
            moment.locale(userLocale);
            element.timestamp = moment(parseInt(element.timestamp * 1000)).utc().format("YYYY-MM-DD");
            element.netspace = this.getFormatedPoolBytesTb(element.netspace);
            this.farmerCapacityChart.push({ "x" : element.timestamp, "y" : element.netspace })
          });
          this.seriesFarmerCapacity = [{ name: this.$i18n.t('myFarm-screen.farmerGrowthLabel'), data: this.farmerCapacityChart }];
          this.partialsList = res.data.partials;
          this.partialsList.forEach(element => {
            element.timestamp = moment(parseInt(element.timestamp * 1000)).utc().format("YYYY-MM-DD LT");
            this.chartPoints.push({ "x" : element.timestamp, "y" : element.points });
            this.chartPartials.push({ "x" : element.timestamp, "y" : element.partials });
            this.chartPartialsError.push({ "x" : element.timestamp, "y" : element.failedPartials });
          });
          this.seriesPoints = [{ name: 'Points', data: this.chartPoints }]
          this.seriesPartials = [{ name: 'Partials', data: this.chartPartials },{ name: 'Failed Partials', data: this.chartPartialsError }]
          this.roundsList = res.data.payouts.earnings.slice().reverse();
          this.roundsList.forEach(element => {
            moment.locale('nl');
            element.date_earned = moment(parseInt(element.date_earned * 1000)).utc().format("YYYY-MM-DD LT");
            this.chartPayouts.push({ "x": element.date_earned, "y": element.amount });
          });
          this.seriesPayouts = [{ name: "Payout", data: this.chartPayouts }];
          
          this.payoutsChart = res.data.payoutsChart.slice().reverse();
          this.payoutsChart.forEach(element => {
            moment.locale('nl');
            element.timestamp = moment(parseInt(element.timestamp * 1000)).utc().format("YYYY-MM-DD");
            this.dailyPayouts.push({ "x": element.timestamp, "y": element.amount });
          });
          this.dailyPayouts = [{ name: "Payout", data: this.dailyPayouts }];

        }).catch(error => {
          if(error.response) {
            if(error.response.status == '404'){
              localStorage.setItem('token', null);
              localStorage.setItem('launcher_id', null);
              localStorage.setItem('nickname', null);
              this.nickname = null;
            }
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
        })
      this.getDetailedPartials();
      this.getReportEarnings(this.launcher_id, this.currency);
      this.getReportTransactions(this.launcher_id, this.currency);
    },

    async getDetailedPartials() {
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      this.$axios
        .get(
          "v2/partials/list?singleton=" +
            this.launcher_id,
          {
            httpsAgent: agent,
          }
        )
        .then((res) => {
          this.partialsDetailedList = res.data;
          for (let index = 0; index < this.partialsDetailedList.length; index++) {
            moment.locale('nl');
            this.partialsDetailedList[index].timestamp = moment(parseInt(this.partialsDetailedList[index].timestamp)).utc().format("YYYY-MM-DD LT");
            switch(this.partialsDetailedList[index].errorcode) {
              case 0:
                this.partialsDetailedList[index].errorcode = "GOOD"
                break;
              case 1:
                this.partialsDetailedList[index].errorcode = "REVERTED_SIGNAGE_POINT"
                break;
              case 2:
                this.partialsDetailedList[index].errorcode = "TOO_LATE: Make sure your proof of space lookups are fast, and network connectivity is good. Response must happen in less than 25 seconds. NAS or network farming can be an issue."
                break;
              case 3:
                this.partialsDetailedList[index].errorcode = "NOT_FOUND: Did not find signage point or EOS"
                break;
              case 4:
                this.partialsDetailedList[index].errorcode = "INVALID_PROOF: Invalid proof of space"
                break;
              case 5:
                this.partialsDetailedList[index].errorcode = "PROOF_NOT_GOOD_ENOUGH: Proof of space has required iters too high for difficulty"
                break;
              case 6:
                this.partialsDetailedList[index].errorcode = "INVALID_DIFFICULTY"
                break;
              case 7:
                this.partialsDetailedList[index].errorcode = "INVALID_SIGNATURE: Failed to verify signature for launcher_id"
                break;
              case 8:
                this.partialsDetailedList[index].errorcode = "SERVER_EXCEPTION"
                break;
              case 9:
                this.partialsDetailedList[index].errorcode = "INVALID_P2_SINGLETON_PUZZLE_HASH"
                break;
              case 10:
                this.partialsDetailedList[index].errorcode = "FARMER_NOT_KNOWN"
                break;
              case 11:
                this.partialsDetailedList[index].errorcode = "FARMER_ALREADY_KNOWN"
                break;
              case 12:
                this.partialsDetailedList[index].errorcode = "INVALID_AUTHENTICATION_TOKEN"
                break;
              case 13:
                this.partialsDetailedList[index].errorcode = "INVALID_PAYOUT_INSTRUCTIONS"
                break;
              case 14:
                this.partialsDetailedList[index].errorcode = "INVALID_SINGLETON"
                break;
              case 15:
                this.partialsDetailedList[index].errorcode = "DELAY_TIME_TOO_SHORT"
                break;
              case 16:
                this.partialsDetailedList[index].errorcode = "REQUEST_FAILED"
                break;
              case 1001:
                this.partialsDetailedList[index].errorcode = "UPGRADE_NEEDED: This partial was rejected because it was generated from a too old CHIA client. upgrade your farm to v1.3 or higher"
                break;
              default:
                this.partialsDetailedList[index].errorcode = "OTHER";
            }
          }
          // Chart Partial Donuts Data
          this.seriesPartialsDonut.push(Enumerable.from(this.partialsDetailedList).where(w => w.errorcode == 'GOOD').count());
          this.seriesPartialsDonut.push(Enumerable.from(this.partialsDetailedList).where(w => w.errorcode == 'INVALID_SIGNATURE').count());
          this.seriesPartialsDonut.push(Enumerable.from(this.partialsDetailedList).where(w => w.errorcode == 'TOO_LATE').count());
          this.seriesPartialsDonut.push(Enumerable.from(this.partialsDetailedList).where(w => w.errorcode == 'PROOF_NOT_GOOD_ENOUGH').count());
          this.seriesPartialsDonut.push(Enumerable.from(this.partialsDetailedList).where(w => w.errorcode == 'NOT_FOUND').count());
        }).catch(error => {console.log(error.response.status)});
    },

    async getReportEarnings(singleton) {
      let currencyToQuery = localStorage.getItem('currency')
      console.log(currencyToQuery);
      if (currencyToQuery == null){
        currencyToQuery = "usd"
      }
      this.currency = currencyToQuery.toUpperCase();
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      this.$axios
        .get(
          "v1/report/earnings/day?singleton=" +
            singleton + "&currency=" + currencyToQuery,
          {
            httpsAgent: agent,
          }
        )
        .then((res) => {
          this.reportEarningsList = res.data;
          moment.locale('nl');
          for (let index = 0; index < this.reportEarningsList.length; index++) {
            this.reportEarningsList[index].timestamp = moment(parseInt(this.reportEarningsList[index].timestamp * 1000)).utc().format("YYYY-MM-DD LT");
          }
          this.jsonReportEarningsData = JSON.stringify(this.reportEarningsList);
        }).catch(error => {console.log(error.response.status)});
    },
    
    async getReportTransactions(singleton) {
      let currencyToQuery = localStorage.getItem('currency')
      console.log(currencyToQuery);
      if (currencyToQuery == null){
        currencyToQuery = "usd"
      }
      this.currency = currencyToQuery.toUpperCase();
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      this.$axios
        .get(
          "v1/report/payouts?singleton=" +
            singleton + "&currency=" + currencyToQuery,
          {
            httpsAgent: agent,
          }
        )
        .then((res) => {
          this.reportTransactionsList = res.data;
          moment.locale('nl');
          for (let index = 0; index < this.reportTransactionsList.length; index++) {
            this.reportTransactionsList[index].timestamp = moment(this.reportTransactionsList[index].timestamp).format("YYYY-MM-DD LT");
          }
          this.jsonReportEarningsData = JSON.stringify(this.reportTransactionsList);
        }).catch(error => {console.log(error.response.status)});
    },
    getFormatedPoolBytesWithoutSize(bytes,decimals = 2) {
      if (bytes === 0) return "0";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i))).toFixed(dm);
    },

    getFormatedPoolBytesTb(bytes,decimals = 2) {
      if (bytes === 0) return "0";
      const k = 1024;
      return parseFloat((bytes / k ** 4).toFixed(decimals));
    },

    getFormatedPoolBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      var sizes = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat(bytes / Math.pow(k, i)).toFixed(dm) + " " + sizes[i];
    },

    toggleTheme() {
        this.theme = this.theme == 'darkMode' ? '' : 'darkMode';
        document.documentElement.setAttribute('data-theme', this.theme); 
        localStorage.setItem('theme', this.theme);
        this.updateGraphTheme(this.theme);
    },
    countDownTimer() {
      if(this.autoRefresh == 'enable'){
        if(this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        }else{
          this.$router.go()
        }
      }
    },
    updateGraphTheme(theme) {
        if(theme == 'darkMode'){
            this.chartOptionsPoints = {
              dataLabels: { enabled: false },
              theme: { mode: 'dark' },
              colors : ["#06a74d"]
            }
            this.chartOptionsPartials = {
              dataLabels: { enabled: false },
              theme: { mode: 'dark' },
              stacked: true,
              colors : ['#06a74d','#FFA500']
            }
            this.chartOptionsPayouts = {
              dataLabels: { enabled: false },
              theme: { mode: 'dark' },
              colors : ["#06a74d"]
            }
            this.chartOptionsNetspace = {
              dataLabels: { enabled: true },
              theme: { mode: 'dark' },
              colors : ["#06a74d"]
            }
            this.chartOptionsPartialsDonut = {
              dataLabels: { enabled: false },
              plotOptions: { pie: { customScale: 0.8 } },
              labels: ['GOOD', 'INVALID_SIGNATURE', 'TOO_LATE', 'PROOF_NOT_GOOD', 'NOT_FOUND'],
              theme:{ mode: 'dark', palette: 'palette5' }
            }
            this.isDarkMode = true,
            this.tableTheme = "black-rhino"
        }else{
            this.chartOptionsPoints = {
              dataLabels: { enabled: false },
              theme: { mode: 'light' },
              colors : ["#06a74d"]
            }
            this.chartOptionsPartials = {
              dataLabels: { enabled: false },
              theme: { mode: 'light' },
              stacked: true,
              colors : ['#06a74d','#FFA500']
            }
            this.chartOptionsPayouts = {
              dataLabels: { enabled: false },
              theme: { mode: 'light' },
              colors : ["#06a74d"]
            }
            this.chartOptionsNetspace = {
              dataLabels: { enabled: true },
              theme: { mode: 'light' },
              colors : ["#06a74d"]
            }
            this.chartOptionsPartialsDonut = {
              dataLabels: { enabled: false },
              plotOptions: { pie: { customScale: 0.8 } },
              labels: ['GOOD', 'INVALID_SIGNATURE', 'TOO_LATE', 'PROOF_NOT_GOOD', 'NOT_FOUND'],
              theme:{ mode: 'light', palette: 'palette5' }
            }
            this.isDarkMode = false,
            this.tableTheme = "polar-bear"
        }
    }
  },
  created() {
    this.nickname = localStorage.getItem('nickname');
    this.launcher_id = localStorage.getItem('launcher_id');
    this.token = localStorage.getItem('token');
    this.appQRCode = "{ \"xchpoolAccessToken\" : \"" + this.token + "\"}";
    this.autoRefresh = localStorage.getItem('autoRefresh');
    let localTheme = localStorage.getItem('theme');
    document.documentElement.setAttribute('data-theme', localTheme);
    this.theme = localTheme;
    this.updateGraphTheme(this.theme);
    this.performSearch();
  },
  mounted(){
    this.countDownTimer();
  }
};
</script>
<style scoped>
.theme--light.v-application {
  background: transparent;
}
</style>