<template>
  <section class="wrapper home_wrapper">
    <div class="container">
      <div class="row row-header">
        <div class="col-sm-6">
          <img src="@/assets/images/xchpool-logo-white-bg.jpg" alt="" width="0" height="0" class="store-logo-wh-bg" />
          <toggle-button @change="toggleTheme" :value="isDarkMode" :labels="{checked: 'Light', unchecked: 'Dark'}" :color="{checked: '#989898', unchecked: '#383838'}" :width="60" />
        </div>
        <div class="col-sm-6 align-right">
          <a href="/myfarm" class="settingLink">
            <svg id="Capa_1" enable-background="new 0 0 511.999 511.999" height="30" viewBox="0 0 511.999 511.999" width="30" xmlns="http://www.w3.org/2000/svg"><path d="m346.999 457.516h-196v-90h196c41.355 0 75-33.645 75-75s-33.645-75-75-75h-170v73.032l-176.999-118.032 176.999-118.032v73.032h170c90.981 0 165 74.019 165 165s-74.019 165-165 165zm-166-30h166c74.439 0 135-60.561 135-135s-60.561-135-135-135h-200v-46.968l-92.927 61.968 92.927 61.968v-46.968h200c57.897 0 105 47.103 105 105s-47.103 105-105 105h-166z"/></svg>
          </a>
        </div>
      </div>
       <div class="row row-margin-bottom" v-if="tokenValid">
          <div class="col-sm-12">
            <div class="graphbox-welcome box">
              <h5 class="text-center">{{ $t('settings-screen.welcomeTitle') }}</h5>
               <div class="text-center" > {{ $t('settings-screen.configurationTitle') }}<br /> {{launcher_id}} </div>
              <div class="graph_wrap"></div>
            </div>
          </div>
       </div>
      <div class="row row-margin-bottom" v-if="!tokenValid">
          <div class="col-sm-12">
            <div class="graphbox-welcome box">
              <h5 class="text-center">{{ $t('settings-screen.tokenInvalidTitle') }}</h5>
               <div class="text-center" > {{ $t('settings-screen.tokenInvalidMessage') }}</div>
              <div class="graph_wrap"></div>
            </div>
          </div>
       </div>
      <div class="row row-margin-bottom" v-if="tokenValid">
        <div class="col-sm-6">
            <div class="graphBox box">
              <h5 class="text-center">{{ $t('settings-screen.autoRefreshTitle') }}</h5>
              <div class="text-center" > {{ $t('settings-screen.autoRefreshDescription') }}</div>
              <div class="text-center">
                <br/>
                <toggle-button @change="toggleAutoRefresh" :value="autoRefresh" :labels="{checked: 'Disable', unchecked: 'Enable'}" :color="{checked: '#989898', unchecked: '#345322'}" :width="80" />
              </div>
              <div class="graph_wrap"></div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="graphBox box">
              <h5 class="text-center">{{ $t('settings-screen.nicknameTitle') }}</h5>
              <div class="text-center">
                <input class="form-input" v-model="nickName">
                <br/>
                <button @click="udpateNickname" class="custom-button"> {{ $t('settings-screen.saveButton') }} </button>
              </div>
              <div class="graph_wrap"></div>
            </div>
          </div>
      </div>
      <div class="row row-margin-bottom" v-if="tokenValid">
          <div class="col-sm-6">
            <div class="graphbox big-box box">
              <br/>
              <h5 class="text-center">Partials target</h5>
               <div class="text-center" > 
                 The partials target is the target number of partials the farmer wishes to send per day. difficulty will be adjusted so, that the number of partials per day will match the partials_target. pool reference default is 300. XCHpool currently allows farmers to set this value between 200 and 900. please note that if you set this value, it can take up to a day to be in effect.
                <br/><br/>
                Your actual target is : {{partials_target}}
                <br/><br/>
                </div>
                <div class="text-center" > 
                  <select v-model="difficultySelected" class="select-css">
                    <option v-for="diff in difficulties"  v-bind:key="diff" :value="diff">{{diff}}</option>
                  </select>
                </div>
                <div class="text-center" > 
                  <br/>
                <button @click="udpateDifficulty" class="custom-button"> {{ $t('settings-screen.saveButton') }} </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button @click="resetDifficulty" class="custom-button"> {{ $t('settings-screen.resetDefaultButton') }} </button>
              </div>
              <div class="graph_wrap"></div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="graphbox big-box box">
              <br/>
              <h5 class="text-center">Minimum Payout</h5>
               <div class="text-center" > 
                 The minimum payout amount is 0.01 XCH. 
                 The maximum depends on the farmer. everyone can at least set it to 0.1 XCH but large farmers are able to set it to a much higher value.
                <br/><br/>
                Your actual minimum payout is : {{min_payout}} XCH
                <br/><br/>
                </div>
                <div class="text-center" > 
                  <input class="form-input" v-model="target_min_payout">
                  <br/>
                  <button @click="udpateMinPayout" class="custom-button"> {{ $t('settings-screen.saveButton') }} </button>
                  <br/>
                </div>
              <div class="graph_wrap"></div>
            </div>
          </div>
       </div>
       <div class="row row-margin-bottom" v-if="tokenValid">
          <div class="col-sm-12">
            <div class="graphbox big-box box">
              <br/>
              <h5 class="text-center">Currency selection</h5>
               <div class="text-center" > 
                 Select the currency you want to use in the reporting.
                <br/><br/>
                Actual currency selected : {{currencySelected}}
                <br/><br/>
                </div>
                <div class="text-center" > 
                  <select v-model="currencySelected" class="select-css">
                    <option v-for="currency in currencies"  v-bind:key="currency.toUpperCase()" :value="currency">{{currency.toUpperCase()}}</option>
                  </select>
                </div>
                <div class="text-center" > 
                  <br/>
                <button @click="udpateCurrency" class="custom-button"> {{ $t('settings-screen.saveButton') }} </button>
              </div>
              <div class="graph_wrap"></div>
            </div>
          </div>
       </div>
    </div>
  </section>
</template>

<script>
import https from "https";

export default {
  name: "Settings",
  data() {
    return {
      theme: '',
      autoRefresh: '',
      isDarkMode: false,
      authResult: {},
      launcher_id: '',
      token: '',
      tokenValid: false,
      nickName: '',
      message: '',
      errorCode: '',
      errorMessage: '',
      difficulties : ['200', '300', '400', '500', '600', '700', '800', '900'],
      difficultySelected: null, 
      currencies: [],
      currencySelected: null, 
      currency_selection: null,
      partials_target: null, 
      min_payout: null,
      target_min_payout: null
    };
  },

  methods: {
    checkTokenValid() {
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      const headers = { 
        "X-Api-Key": this.token
      };
      this.$axios
        .get("v1/farmer/get", {httpsAgent: agent, headers: headers})
        .then((res) => {
          this.authResult = res.data;
          this.launcher_id = this.authResult.launcher_id;
          this.nickName = this.authResult.nickname;
          this.partials_target = this.authResult.partials_target;
          this.min_payout = (this.authResult.min_payout/1000000000000).toFixed(2);

          localStorage.setItem('token', this.token);
          localStorage.setItem('launcher_id', this.launcher_id);
          localStorage.setItem('nickname', this.nickName);

          this.tokenValid = true;
        })
        .catch(error => {
          if(error.response.status == 404){
            this.errorCode = error.response.status;
            this.message = this.$i18n.t('settings-screen.tokenNotFound');
            this.nickName = '';
          }
          if(error.response.status == 401){
            this.errorCode = error.response.status;
            this.message = this.$i18n.t('settings-screen.tokenExpired');
          }
        });
    },

    getCurrencyList() {
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      const headers = { 
        "X-Api-Key": this.token
      };
      this.$axios
        .get("v1/currencies/list", {httpsAgent: agent, headers: headers})
        .then((res) => {
          let tempData = res.data;
          tempData.forEach(element => {this.currencies.push(element);});
        })
        .catch(error => {
          console.log("Error getting currencies : " + error.message)
        });
    },

    difficultyOnSelect(payload) {
       this.difficultySelected = payload;
    },

    validateSelection(){
      console.log("diff update");
    },

    async udpateNickname(){
      let queryBody = {
        "nickname": this.nickName,
        "token": this.token
      };
      const agent = new https.Agent({
        rejectUnauthorized: false,
        method:'POST'
      });
      const headers = { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-Api-Key': this.token
      };
      await this.$axios
        .post("v1/farmer/nickname", queryBody, {httpsAgent: agent, headers: headers})
        .then((res) => {
          if(res.data.result == "OK"){
            localStorage.setItem('token', this.token);
            localStorage.setItem('launcher_id', this.launcher_id);
            localStorage.setItem('nickname', this.nickName);
            this.$swal('Successfully saved');
          }
          else{
            this.$swal(this.$i18n.t('settings-screen.failUpdateNickName'));
          }
        })
        .catch(error => {
          this.errorMessage = error.message;
          this.$swal(this.errorMessage);
          console.error("There was an error!", error);
        });
          
    },
    async udpateMinPayout(){
      console.log("Starting update Min Payout")
      if(parseFloat(this.target_min_payout) >= 0.01){
        let tMinP = parseFloat(this.target_min_payout) * 1000000000000
        console.log(tMinP)
        let queryBody = {
          "min_payout": tMinP
        };
        let headers = {
          'X-Api-Key': this.token
        }
        const agent = new https.Agent({
          rejectUnauthorized: false,
          method:'POST'
        });
        await this.$axios
          .post("v1/farmer/min_payout", queryBody, {httpsAgent: agent, headers: headers})
          .then((res) => {
            if(res.data.result == "OK"){
              this.$swal('Successfully saved');
              this.min_payout = this.target_min_payout
            }
            else{
              this.$swal("Update failed, try again later");
            }
          })
          .catch(error => {
            this.errorMessage = error.message;
            this.$swal(error.message);
          });
      } else{
        this.$swal("Minimum payout too low, please put at least 0.01 XCH");
      }      
    },

    async udpateDifficulty(){
      let queryBody = {
        "target": this.difficultySelected,
        "token": this.token
      };
      console.log(queryBody);
      const agent = new https.Agent({
        rejectUnauthorized: false,
        method:'POST'
      });
      await this.$axios
        .post("/v1/farmer/partials_target", queryBody, agent,
        {
          headers : {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'X-Api-Key': this.token
          }
        })
        .then((res) => {
          if(res.data.result == "OK"){
            this.partials_target = this.difficultySelected;
            this.$swal('Successfully saved');
          }
          else{
            this.$swal("Update failed, try again later");
          }
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
          
    },
    
    async resetDifficulty(){
      let queryBody = {
        "target": "300",
        "token": this.token
      };
      console.log(queryBody);
      const agent = new https.Agent({
        rejectUnauthorized: false,
        method:'POST'
      });
      await this.$axios
        .post("/v1/farmer/partials_target", queryBody, agent,
        {
          headers : {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'X-Api-Key': this.token
          }
        })
        .then((res) => {
          if(res.data.result == "OK"){
            this.partials_target = "300";
            this.$swal('Successfully saved');
          }
          else{
            this.$swal("Update failed, try again later");
          }
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
          
    },

    async udpateCurrency(){
      localStorage.setItem('currency', this.currencySelected);
          
    },

    toggleAutoRefresh(){
      this.autoRefresh = this.autoRefresh == false ? true : false;
      if(this.autoRefresh == true){
        localStorage.setItem('autoRefresh', 'enable');
      }else{
        localStorage.setItem('autoRefresh', 'disable');
      }
      console.log(this.autoRefresh);
    },

    toggleTheme() {
      this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
      document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
      localStorage.setItem('theme', this.theme); // stores theme value on local storage
    }
  },

  created() {
    this.token = localStorage.getItem('token');
    this.launcher_id = localStorage.getItem('launcher_id');
    this.nickName = localStorage.getItem('nickname');
    this.currencySelected = localStorage.getItem('currency');
    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any    
    document.documentElement.setAttribute('data-theme', localTheme);
    this.theme = localTheme;
    let lsAutoRefresh = localStorage.getItem('autoRefresh');
    if(lsAutoRefresh == 'enable'){
      this.autoRefresh = true;
    }else{
      this.autoRefresh = false;
    }
    console.log("autoRefresh Value");
    console.log(this.autoRefresh);
    this.checkTokenValid();
    this.getCurrencyList();
  }
};

</script>

<style lang="scss" scoped>
.theme--light.v-application {
      background: transparent;
    }
</style>