<template>
  <section class="wrapper home_wrapper">
      <div class="container">
        <div class="row row-header">
          <div class="col-sm-12">
            <toggle-button @change="toggleTheme" :value="isDarkMode" :labels="{checked: 'Light', unchecked: 'Dark'}" :color="{checked: '#989898', unchecked: '#383838'}" :width="60" />
          </div>
        </div>
        <div class="row row-margin-bottom">
          <div class="col-sm-12">
            <div class="graphBox box">
              <h3>{{ $t('transactionList-screen.transactionDetailsLabel') }} {{ this.transactionId }}</h3>
              <b>{{ $t('transactionList-screen.createdAtTimeLabel') }}</b> {{ transactionData.created_at_time }} <br/>
              <b>{{ $t('transactionList-screen.confirmedLabel') }}</b> {{ transactionData.confirmed }} <br/>
              <b>{{ $t('transactionList-screen.confirmedAtHeighLabel') }}</b> {{ transactionData.confirmed_at_height }}<br/>
              <b>{{ $t('transactionList-screen.rewardAddressLabel') }}</b> {{ transactionData.fee_amount }}<br/>
            </div>
          </div>
      </div>
        <div class="row row-margin-bottom">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <div class="h1">
                          <vue-good-table
                            :columns="columns"
                            :rows="transactionTableData"
                            :sort-options="{
                              enabled: true
                            }"
                            :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 10,
                              position: 'bottom',
                              perPageDropdown: [20, 50, 100],
                              dropdownAllowAll: false,
                              setCurrentPage: 1,
                              nextLabel: 'next',
                              prevLabel: 'prev',
                              rowsPerPageLabel: 'Lines per page',
                              ofLabel: 'of',
                              pageLabel: 'page', 
                              allLabel: 'All' 
                            }"
                            >
                          </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
      </div>
  </section>
</template>

<script>
// @ is an alias to /src
import https from "https";
import moment from "moment";
import getUserLocale from "get-user-locale";

export default {
  name: "PayoutList",
  data() {
    return {
      theme: '',
      isDarkMode: false,
      tableTheme: 'black-rhino',
      transactionId: "",
      transactionData: {},
      transactionTableData: [],
      columns: [{
          label: 'Reward Address',
          field: 'puzzle_hash',
          type: 'string'
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'number'
        },
        {
          label: 'Parent Coint',
          field: 'parent_coin_info',
          type: 'string'
        }
        ],
    };
  },

  methods: {
    init() {
      let userLocale = getUserLocale();
      
      if (Object.keys(this.$route.params).length) {
        this.transactionId = this.$route.params.transaction_id;
      } else {
        return false;
      }
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      this.$axios
        .get(
          "v2/transaction/get?transaction_id=" +
            this.transactionId,
          {
            httpsAgent: agent,
          }
        )
        .then((res) => {
          moment.locale(userLocale);
          this.transactionData = res.data;

          this.transactionData.created_at_time = moment(this.transactionData.created_at_time * 1000).utc().format("YYYY-MM-DD LT");
          
          this.transactionData.additions.forEach(element => {
              element.amount = this.getFormatedPoolAmount(element.amount);
              this.transactionTableData.push({
                  "amount" : element.amount / 1000000000000,
                  "parent_coin_info" : element.parent_coin_info,
                  "puzzle_hash" : element.puzzle_hash
              })
          });
        });
    },
    getFormatedPoolBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      var sizes = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat(bytes / Math.pow(k, i)).toFixed(dm) + " " + sizes[i];
    },
    getFormatedPoolAmount(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat(bytes / Math.pow(k, i)).toFixed(dm);
    },
    toggleTheme() {
                this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
                document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
                localStorage.setItem('theme', this.theme); // stores theme value on local storage
                this.updateGraphTheme(this.theme);
    },
    updateGraphTheme(theme) {
        if(theme == 'darkMode'){
            this.tableTheme = "black-rhino",
            this.isDarkMode = true;
        }else{
            this.tableTheme = "polar-bear",
            this.isDarkMode = false;
        }
        
    }
  },

  created() {
    this.init();
    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
    document.documentElement.setAttribute('data-theme', localTheme);
    this.theme = localTheme;
    this.updateGraphTheme(this.theme);
  },
  mounted(){
    
  }
};
</script>
<style scoped>
.theme--light.v-application {
  background: transparent;
}
.fit-text{
      overflow-wrap: break-word;
}
</style>
