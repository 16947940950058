var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"wrapper home_wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row row-header"},[_c('div',{staticClass:"col-sm-12"},[_c('toggle-button',{attrs:{"value":_vm.isDarkMode,"labels":{checked: 'Light', unchecked: 'Dark'},"color":{checked: '#989898', unchecked: '#383838'},"width":60},on:{"change":_vm.toggleTheme}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"box box-padding"},[_c('h5',[_vm._v(_vm._s(_vm.$t('poolEarnings-screen.earningsTitle')))]),_c('p',[_vm._v(_vm._s(_vm.$t('poolEarnings-screen.earningsDescription')))]),_c('div',{staticClass:"h1 text-center"},[_c('apexchart',{attrs:{"height":"350","options":_vm.chartOptions,"series":_vm.chartSeries}})],1),_c('div',{staticClass:"graph_wrap"})])])]),_c('div',{staticClass:"row row-margin-bottom"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"box box-padding"},[_c('div',{staticClass:"h1"},[_c('vue-good-table',{attrs:{"columns":_vm.columnsEarnings,"rows":_vm.poolEarnings,"sort-options":{
                            enabled: true,
                            initialSortBy: {field: 'timestamp', type: 'desc'}
                          },"theme":_vm.tableTheme,"pagination-options":{
                            enabled: true,
                            mode: 'records',
                            perPage: 10,
                            position: 'bottom',
                            perPageDropdownEnabled: false,
                            perPageDropdown: [20, 50, 100],
                            dropdownAllowAll: false,
                            setCurrentPage: 1,
                            nextLabel: _vm.$t('poolEarnings-screen.tableNextLabel'),
                            prevLabel: _vm.$t('poolEarnings-screen.tablePrevLabel'),
                            rowsPerPageLabel: _vm.$t('poolEarnings-screen.tableLinesPerPagesLabel'),
                            ofLabel: _vm.$t('poolEarnings-screen.tableOfLabel'),
                            pageLabel: _vm.$t('poolEarnings-screen.tablePageLabel'), 
                            allLabel: _vm.$t('poolEarnings-screen.tableAllLabel') 
                          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'payout_id')?_c('span',[_c('router-link',{attrs:{"to":{ name: 'PayoutList', query: { payout_id: props.row.payout_id }}}},[_vm._v(_vm._s(props.row.payout_id))])],1):_vm._e()]}}])})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }