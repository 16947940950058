<template>
<section class="wrapper home_wrapper">
    <div class="container">
      <div class="row row-header">
        <div class="col-sm-12">
          <toggle-button @change="toggleTheme" :value="isDarkMode" :labels="{checked: 'Light', unchecked: 'Dark'}" :color="{checked: '#989898', unchecked: '#383838'}" :width="60" />
        </div>
      </div>
      <div class="row row-margin-bottom">
          <div class="col-sm-12">
            <div class="graphBox box">
            <h3>{{ $t('payoutList-screen.payoutIDLabel') }} {{ this.$route.query.payout_id }}</h3>
            </div>
          </div>
      </div>
      <div class="row row-margin-bottom">
          <div class="col-sm-4">
            <div class="graphBox box">
              <h5>{{ $t('payoutList-screen.totalRewardAmoutLabel') }}</h5>
              <div class="h1 text-center capacity_bytes">{{ data.totalRewardAmount }}</div>
              <div class="graph_wrap"></div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="graphBox box">
              <h5>{{ $t('payoutList-screen.dateLabel') }}</h5>
              <div class="h1 text-center last_reward">{{data.date}}</div>
              <div class="graph_wrap"></div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="graphBox box">
              <h5>{{ $t('payoutList-screen.farmersInPayoutLabel') }}</h5>
              <div class="h1 text-center"> {{ payouts.length -1  }} </div>
              <div class="graph_wrap"></div>
            </div>
          </div>
      </div>
      <div class="row row-margin-bottom">
          <div class="col-sm-12">
            <div class="graphBox box">
            <h4>{{ $t('payoutList-screen.payoutInformationsLabel') }}</h4>
            <p>
              {{ $t('payoutList-screen.payoutInformationsDescription') }}
            </p>
            </div>
          </div>
      </div>
        <div class="row row-margin-bottom">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <div class="h1">
                          <vue-good-table
                            :columns="columns"
                            :rows="payouts"
                            :theme="tableTheme"
                            :sort-options="{
                              enabled: true,
                            }"
                            :fixed-header="true"
                            :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 10,
                              position: 'bottom',
                              perPageDropdownEnabled: false,
                              perPageDropdown: [20, 50, 100],
                              dropdownAllowAll: false,
                              setCurrentPage: 1,
                              nextLabel: $t('payoutList-screen.tableNextLabel'),
                              prevLabel: $t('payoutList-screen.tablePrevLabel'),
                              rowsPerPageLabel: $t('payoutList-screen.tableLinesPerPagesLabel'),
                              ofLabel: $t('payoutList-screen.tableOfLabel'),
                              pageLabel: $t('payoutList-screen.tablePageLabel'), 
                              allLabel: $t('payoutList-screen.tableAllLabel') 
                            }">
                              <template slot="table-row" slot-scope="props">
                              <span v-if="props.column.field == 'singleton'">
                                <router-link
                                  :to="{
                                    name: 'Membersearch',
                                    query: { singleton: props.row.singleton },
                                  }"
                                  >{{ props.row.singleton }}</router-link>
                              </span> 
                              <span v-if="props.column.field == 'amount'">
                                {{ props.row.amount }}
                              </span> 
                              <span v-if="props.column.field == 'state'">
                                {{ props.row.state }}
                              </span>  
                              <span v-if="props.column.field == 'transaction_id'">
                                <router-link
                                  :to="{
                                    name: 'TransactionList',
                                    params: { transaction_id: props.row.transaction_id },
                                  }"
                                  >{{ props.row.transaction_id }}</router-link>
                              </span>
                            </template>
                          </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import https from "https";
import moment from "moment";
import getUserLocale from "get-user-locale";

export default {
  name: "PayoutList",
  data() {
    return {
      theme: '',
      isDarkMode: 'false',
      tableTheme: 'black-rhino',
      searchKeyword: "",
      sortBy: "singleton",
      sortDesc: true,
      columns: [{
          label: this.$i18n.t('payoutList-screen.tableColumnFarmerLauncherID'),
          field: 'singleton',
        },
        {
          label: this.$i18n.t('payoutList-screen.tableColumnAmount'),
          field: 'amount',
          type: 'number',
        },
        {
          label: this.$i18n.t('payoutList-screen.tableColumnState'),
          field: 'state',
          type: 'string',
        },
        {
          label: this.$i18n.t('payoutList-screen.tableColumnTransactionID'),
          field: 'transaction_id',
          type: 'number',
        }
        ],
      payouts: [],
      data: {},
    };
  },

  methods: {
    init() {
      var $this = this;
      let searchField;
      if (Object.keys($this.$route.query).length) {
        searchField = "singleton";
        $this.searchKeyword = $this.$route.query.singleton;
        if ($this.$route.query.payout_id != undefined) {
          searchField = "payout_id";
          $this.searchKeyword = $this.$route.query.payout_id;
        }
      } else {
        return false;
      }
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      this.$axios
        .get(
          "v1/payouts/list?" +
            searchField +
            "=" +
            $this.searchKeyword,
          {
            httpsAgent: agent,
          }
        )
        .then((res) => {
          let userLocale = getUserLocale();
          moment.locale(userLocale);
          res.data.date = moment(parseInt(res.data.date * 1000)).utc().format("YYYY-MM-DD LT");
          $this.data = res.data;
          $this.payouts = res.data.earnings;
        });
    },
    toggleTheme() {
                this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
                document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
                localStorage.setItem('theme', this.theme); // stores theme value on local storage
                this.updateGraphTheme(this.theme);
    },
    updateGraphTheme(theme) {
        if(theme == 'darkMode'){
            this.tableTheme = "black-rhino",
            this.isDarkMode = true;
        }else{
            this.tableTheme = "polar-bear",
            this.isDarkMode = false;
        }
        
    }
  },

  created() {
    this.init();
    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
    document.documentElement.setAttribute('data-theme', localTheme);
    this.theme = localTheme;
    this.updateGraphTheme(this.theme);
  }
};
</script>

<style scoped>
.theme--light.v-application {
  background: transparent;
}
</style>
