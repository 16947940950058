<template>
  <section class="wrapper home_wrapper">
    <div class="container">
      <div class="row row-header">
        <div class="col-sm-12">
          <toggle-button @change="toggleTheme" :value="isDarkMode" :labels="{checked: 'Light', unchecked: 'Dark'}" :color="{checked: '#989898', unchecked: '#383838'}" :width="60" />
        </div>
      </div>
      <div class="row row-margin-bottom">
          <div class="col-sm-3">
            <div class="graphBox box">
              <h5>{{ $t('memberList-screen.minMemberNetspace') }}</h5>
              <div class="h1 text-center last_reward"> 0 </div>
              <div class="graph_wrap"></div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="graphBox box">
              <h5>{{ $t('memberList-screen.maxMemberNetspace') }}</h5>
              <div class="h1 text-center capacity_bytes">{{poolStats.maxMemberSpace}}</div>
              <div class="graph_wrap"></div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="graphBox box">
              <h5>{{ $t('memberList-screen.avgMemberNetspace') }}</h5>
              <div class="h1 text-center">{{poolStats.avgMemberSpace}}</div>
              <div class="graph_wrap"></div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="graphBox box">
              <h5>{{ $t('memberList-screen.activeMembers') }}</h5>
              <div class="h1 text-center">{{ poolStats.poolMembers }}</div>
              <div class="graph_wrap"></div>
            </div>
        </div>
      </div>
            <div class="row row-margin-bottom">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <h5>{{ $t('memberList-screen.leaderBoardTitle') }}</h5>
                        <p>{{ $t('memberList-screen.leaderBoardDescription') }}</p>
                        <div class="h1">
                          <vue-good-table
                            id ="membersTable"
                            :columns="columns"
                            :rows="members"
                            :theme="tableTheme"
                            :sort-options="{
                              enabled: true,
                              initialSortBy: {field: 'currentPoolShare', type: 'desc'}
                            }"
                            :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 20,
                              position: 'bottom',
                              perPageDropdownEnabled: false,
                              perPageDropdown: [20, 50, 100],
                              dropdownAllowAll: false,
                              setCurrentPage: 1,
                              nextLabel: $t('memberList-screen.tableNextLabel'),
                              prevLabel: $t('memberList-screen.tablePrevLabel'),
                              rowsPerPageLabel: $t('memberList-screen.tableLinesPerPagesLabel'),
                              ofLabel: $t('memberList-screen.tableOfLabel'),
                              pageLabel: $t('memberList-screen.tablePageLabel'), 
                              allLabel: $t('memberList-screen.tableAllLabel') 
                            }"
                            @on-row-click="toMemberSearch"
                            >
                            <template slot="table-row" slot-scope="props">
                              <span v-if="props.column.field == 'singleton'">
                                <span v-if="props.row.nickname">{{ props.row.nickname }}</span>
                                <span v-if="!props.row.nickname">{{ props.row.singleton }}</span>
                              </span>
                              <span v-if="props.column.field == 'netspace'">
                                <span>{{ props.row.netspace }}</span> 
                              </span>
                              <span v-if="props.column.field == 'currentPoolShare'">
                                <span>{{ props.row.currentPoolShare }}</span> 
                              </span>
                            </template>
                          </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  </section>
</template>

<script>
import https from "https";
import moment from "moment";
import getUserLocale from "get-user-locale";


export default {
  name: "Memberlist",
  data() {
    return {
      theme: '',
      isDarkMode: false,
      poolStats: {},
      tableTheme: 'black-rhino', //polar-bear 
      sortBy: "netspace",
      sortDesc: true,
      columns: [{
          label: this.$i18n.t('memberList-screen.tableColumnLauncherID'),
          field: 'singleton',
        },
        {
          label: this.$i18n.t('memberList-screen.tableColumnNetspace'),
          field: 'netspace',
          type: 'number',
        },
        {
          label: this.$i18n.t('memberList-screen.tableColumnCurrentPoolShare'),
          field: 'currentPoolShare',
          type: 'number',
        }
        ],
      members: [],
    };
  },

  methods: {
    init() {
      let userLocale = getUserLocale();
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      this.$axios
        .get("v1/members/list", {
          httpsAgent: agent,
        })
        .then((res) => {
          this.members = Object.values(res.data.members);
          for (let index = 0; index < this.members.length; index++) {
            // change currentPoolShare format
            this.members[index].currentPoolShare =
              (this.members[index].currentPoolShare * 100).toFixed(4);
            moment.locale(userLocale);
            this.members[index].joined = moment(this.members[index].joined).utc().format("YYYY-MM-DD LT");
            // this.members[index].netspace = (parseInt(this.members[index].netspace) / 1073741824).toFixed(2)
            this.members[index].netspace = this.getFormatedPoolBytes(this.members[index].netspace);
          }
        });
        this.$axios
        .get("v1/poolstats", {
          httpsAgent: agent,
        })
        .then((res) => {
          this.poolStats = res.data;
          this.poolStats.maxMemberSpace = this.getFormatedPoolBytes(this.poolStats.maxMemberSpace);
          this.poolStats.avgMemberSpace = this.getFormatedPoolBytes(this.poolStats.avgMemberSpace);
        });
    },

    getFormatedPoolBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      var sizes = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat(bytes / Math.pow(k, i)).toFixed(dm) + " " + sizes[i];
    },
    
    toMemberSearch(params) {
      this.$router.push("/membersearch?singleton=" + params.row.singleton);
    },
    toggleTheme() {
                this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
                document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
                localStorage.setItem('theme', this.theme); // stores theme value on local storage
                this.updateGraphTheme(this.theme);
    },
    updateGraphTheme(theme) {
        if(theme == 'darkMode'){
            this.tableTheme = "black-rhino",
            this.isDarkMode = true;
        }else{
            this.tableTheme = "polar-bear",
            this.isDarkMode = false;
        }
        
    }
  },

  created() {
    this.init();
    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
    document.documentElement.setAttribute('data-theme', localTheme);
    this.theme = localTheme;
    this.updateGraphTheme(this.theme);
  }
};

</script>

<style lang="scss" scoped>
.theme--light.v-application {
      background: transparent;
    }
</style>