<template>
  <nav class="navbar navbar-expand-lg" id="mainNav">
    <div class="container">
      <div class="navbarbox">
        <div class="navbar-brand ">
           <a target="_blank" href="https://xchpool.org/">
            <img src="@/assets/images/xchpool-logo.png" alt="" width="180" />
          </a>
        </div>
        <button
          type="button"
          class="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#bs-example-navbar-collapse-1"
          aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul class="navbar-nav pull-right">
            <li class="nav-item">
              <router-link to="/" exact-path>
                <p>{{ $t('menu-items.mainLabel') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/myfarm" exact-path>
                <p>{{ $t('menu-items.myfarm') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/memberlist" exact-path>
                <p>{{ $t('menu-items.leaderBoardLabel') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/membersearch" exact-path>
                <p>{{ $t('menu-items.memberSearchLabel') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/poolrewards" exact-path>
                <p>{{ $t('menu-items.blocksFoundLabel') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/poolearnings" exact-path>
                <p>{{ $t('menu-items.earningsLabel') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <a target="_blank" href="https://xchpool.org/guide-to-chia-farming/">{{ $t('menu-items.joinPoolLabel') }}</a>
            </li>
            <li class="nav-item lang-selector">
              <select v-model="$i18n.locale">
                <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang" :selected="selected">{{ lang | capitalize }}</option>
              </select>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import $ from "jquery";
import getUserLocale from 'get-user-locale';

export default {
  name: 'locale-changer',
  data: () => ({
    langs: ['en', 'fr', 'zh' ,'pl', 'ru', 'es', 'de', 'pt', 'it'],
    selected: "en",
    myfarm: false
  }),
  
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      return value.toString().toUpperCase()
    }
  },
  mounted() {
    let userLocale = getUserLocale();
    if(this.langs.includes(userLocale.substring(0,2))){
      this.$i18n.locale = userLocale.substring(0,2);
      this.selected = userLocale.substring(0,2);
    } else { this.selected = "en" }
    $(".navbar-toggle").click(function() {
      if($(".navbar-collapse").hasClass("collapse")){
        $(".navbar-collapse").removeClass("collapse");
      } else {
        $(".navbar-collapse").addClass("collapse");
      }
    });
    $(" .navbar-nav li a").click(function() {
      $(".navbar-collapse").addClass("collapse");
    });
  },
};
</script>
