<template>
    <footer class="footer">
        <div class="container text-center">
            <p>Copyright © 2021 - XCHPool</p>
        </div>
    </footer>
</template>

<script>
    ///import {useRouter} from 'vue-router';
    export default {
        name: 'Footer',
    };
</script>