<template>
  <section class="wrapper home_wrapper">
    <div class="container">
      <div class="row row-header">
        <div class="col-sm-12">
          <toggle-button @change="toggleTheme" :value="isDarkMode" :labels="{checked: 'Light', unchecked: 'Dark'}" :color="{checked: '#989898', unchecked: '#383838'}" :width="60" />
        </div>
      </div>
      <div class="row row-margin-bottom">
          <div class="col-sm-4">
            <div class="graphBox box">
              <h5>{{ $t('poolReward-screen.minBlockTitle') }}</h5>
              <div class="h1 text-center last_reward">{{ minMaxAvgBlocks[0] }}</div>
              <div class="graph_wrap"></div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="graphBox box">
              <h5>{{ $t('poolReward-screen.avgBlockTitle') }}</h5>
              <div class="h1 text-center">{{ minMaxAvgBlocks[1] }}</div>
              <div class="graph_wrap"></div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="graphBox box">
              <h5>{{ $t('poolReward-screen.maxBlockTitle') }}</h5>
              <div class="h1 text-center capacity_bytes">{{ minMaxAvgBlocks[2] }}</div>
              <div class="graph_wrap"></div>
            </div>
          </div>
      </div>
      <div class="row row-padding row-margin-bottom">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <h5>{{ $t('poolReward-screen.wonBlocksTitle') }}</h5>
                        <p>{{ $t('poolReward-screen.wonBlocksDescription') }}</p>
                        <div class="h1 text-center">
                            <apexchart ref="chartWonBlocks" type="line" height="250" :options="chartOptionsWonBlocks" :series="series"></apexchart>
                        </div>
                        <div class="graph_wrap"></div>
                    </div>
                </div>
            </div>
        <div class="row row-margin-bottom">
                <div class="col-sm-12">
                    <div class="box box-padding">
                        <div class="h1" v-if="this.$configJson.Coin == 'XCH'">
                          <vue-good-table
                            :columns="columns"
                            :rows="poolRewards"
                            :sort-options="{
                              enabled: true,
                              initialSortBy: {field: 'block', type: 'desc'}
                            }"
                            :theme="tableTheme"
                            :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 10,
                              position: 'bottom',
                              perPageDropdownEnabled: false,
                              perPageDropdown: [20, 50, 100],
                              dropdownAllowAll: false,
                              setCurrentPage: 1,
                              nextLabel: $t('poolReward-screen.tableNextLabel'),
                              prevLabel: $t('poolReward-screen.tablePrevLabel'),
                              rowsPerPageLabel: $t('poolReward-screen.tableLinesPerPagesLabel'),
                              ofLabel: $t('poolReward-screen.tableOfLabel'),
                              pageLabel: $t('poolReward-screen.tablePageLabel'), 
                              allLabel: $t('poolReward-screen.tableAllLabel') 
                            }"
                            >
                              <template slot="table-row" slot-scope="props">
                              <span v-if="props.column.field == 'timestamp'">
                                {{ props.row.timestamp }}
                              </span> 
                              <span v-if="props.column.field == 'amount'">
                                {{ props.row.amount }}
                              </span> 
                              <span v-if="props.column.field == 'spent'">
                                {{ props.row.spent }}
                              </span>  
                              <span v-if="props.column.field == 'block'">
                                  <a :href="`https://www.chiaexplorer.com/blockchain/block/height/${props.row.block}`" target="_blank">
                                    {{ props.row.block }}
                                  </a>      
                              </span>
                              <span v-if="props.column.field == 'payout_id'">
                                <router-link :to="{ name: 'PayoutList', query: { payout_id: props.row.payout_id }}">{{props.row.payout_id}}</router-link>
                              </span>
                              <span v-if="props.column.field == 'nickname'">
                                <a :href="`/membersearch?singleton=${props.row.launcher_id}`">
                                  {{ props.row.nickname }}
                                </a>                              
                                
                              </span>  
                            </template>
                          </vue-good-table>
                        </div>
                        <div class="h1" v-if="this.$configJson.Coin == 'XCC'">
                          <vue-good-table
                            :columns="columns"
                            :rows="poolRewards"
                            :sort-options="{
                              enabled: true,
                              initialSortBy: {field: 'block', type: 'desc'}
                            }"
                            :theme="tableTheme"
                            :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 10,
                              position: 'bottom',
                              perPageDropdownEnabled: false,
                              perPageDropdown: [20, 50, 100],
                              dropdownAllowAll: false,
                              setCurrentPage: 1,
                              nextLabel: $t('poolReward-screen.tableNextLabel'),
                              prevLabel: $t('poolReward-screen.tablePrevLabel'),
                              rowsPerPageLabel: $t('poolReward-screen.tableLinesPerPagesLabel'),
                              ofLabel: $t('poolReward-screen.tableOfLabel'),
                              pageLabel: $t('poolReward-screen.tablePageLabel'), 
                              allLabel: $t('poolReward-screen.tableAllLabel') 
                            }"
                            >
                              <template slot="table-row" slot-scope="props">
                              <span v-if="props.column.field == 'timestamp'">
                                {{ props.row.timestamp }}
                              </span> 
                              <span v-if="props.column.field == 'amount'">
                                {{ props.row.amount }}
                              </span> 
                              <span v-if="props.column.field == 'spent'">
                                {{ props.row.spent }}
                              </span>  
                              <span v-if="props.column.field == 'block'">
                                  <a :href="`https://explorer.chivescoin.org/blockview.php?hash=undefined&height=${props.row.block}&page=undefined`" target="_blank">
                                    {{ props.row.block }}
                                  </a>
                              </span>
                              <span v-if="props.column.field == 'payout_id'">
                                <router-link :to="{ name: 'PayoutList', query: { payout_id: props.row.payout_id }}">{{props.row.payout_id}}</router-link>
                              </span>
                              <span v-if="props.column.field == 'nickname'">
                                <a :href="`/membersearch?singleton=${props.row.launcher_id}`">
                                  {{ props.row.nickname }}
                                </a>                              
                                
                              </span>  
                            </template>
                          </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import https from "https";
import moment from "moment";
import getUserLocale from "get-user-locale";
import VueApexCharts from 'vue-apexcharts'
    
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

export default {
  name: "Poolrewards",

  data() {
    return {
      theme: '',
      isDarkMode: false,
      tableTheme: 'black-rhino',
      series: [],
      chartOptionsWonBlocks: {
        chart: {
        height: 250,
        type: 'line'
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        },
        noData: {
          text: '...'
        },
        colors : ["#5acc59"]
        },
      poolStats: {},
      poolWonBlocks: [],
      minMaxAvgBlocks: {},
      columns: [
        {
          label: this.$i18n.t('poolReward-screen.tableColumnTimestamp'),
          field: 'timestamp',
        },
        {
          label: this.$i18n.t('poolReward-screen.tableColumnBlock'),
          field: 'block',
          type: 'number',
        },
        {
          label: this.$i18n.t('poolReward-screen.tableColumnAmount'),
          field: 'amount',
          type: 'number',
        },
        {
          label: this.$i18n.t('poolReward-screen.tableColumnSpent'),
          field: 'spent',
          type: 'number',
        },
        {
          label: this.$i18n.t('poolReward-screen.tableColumnPayoutID'),
          field: 'payout_id',
          type: 'number',
        },
        {
          label: this.$i18n.t('poolReward-screen.tableColumnWonByFarmer'),
          field: 'nickname',
          type: 'string',
        },

      ],
      poolRewards: [],
    };
  },

  methods: {
    async getPoolStats() {
                const agent = new https.Agent({
                    rejectUnauthorized: false,
                });
                this.$axios.get("v1/poolstats", {httpsAgent: agent})
                .then( res => {
                    this.poolStats = res.data;
                    this.poolStats.poolCapacityBytes = this.getFormatedPoolBytes(this.poolStats.poolCapacityBytes);
                    this.poolStats.blockchainTotalSpace = this.getFormatedPoolBytes(this.poolStats.blockchainTotalSpace);
                    let userLocale = getUserLocale();
                    moment.locale(userLocale);
                    this.poolStats.lastReward = moment(this.poolStats.lastReward).utc().format("YYYY-MM-DD LT");

                    // Won Blocks Chart
                    this.poolStats.wonBlocks.forEach(element => {
                        moment.locale(userLocale);
                        element.timestamp = moment(parseInt(element.timestamp * 1000)).utc().format("YYYY-MM-DD");
                        element.blocks
                        this.poolWonBlocks.push({
                            "x" : element.timestamp,
                            "y" : element.blocks
                        })
                    }); 
                    this.series = [{
                      name: 'Blocks',
                      data: this.poolWonBlocks
                    }]
                });
            },
    getPoolRewards() {
      var $this = this;
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      this.$axios
        .get("v1/rewards/list", {
          httpsAgent: agent,
        })
        .then((res) => {
          this.poolRewards = Object.values(res.data.rewards);
          this.minMaxAvgBlocks = Object.values(res.data.minMaxAvgBlocks);
          for (let index = 0; index < $this.poolRewards.length; index++) {
            //change spent values to string
            if ($this.poolRewards[index].spent == 0) {
              $this.poolRewards[index].spent = new Boolean(false);
            } else {
              $this.poolRewards[index].spent = new Boolean(true);
            }
            // change join format
            let userLocale = getUserLocale();
            moment.locale(userLocale);
            // $this.$moment.locale("nl");
            $this.poolRewards[index].timestamp = moment(
              $this.poolRewards[index].timestamp
            )
              .utc()
              .format("YYYY-MM-DD LT");
              // console.log($this.$router, $this.$route)
          }
        });
    },
    getFormatedPoolBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      var sizes = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat(bytes / Math.pow(k, i)).toFixed(dm) + " " + sizes[i];
    },
    toggleTheme() {
      this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
      document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
      localStorage.setItem('theme', this.theme); // stores theme value on local storage
      this.updateGraphTheme(this.theme);
    },
    updateGraphTheme(theme) {
      if(theme == 'darkMode'){
        this.chartOptionsWonBlocks = {
          theme: {
              mode: 'dark'
          },
          colors : ["#06a74d"]
        }
        this.isDarkMode = true,
        this.tableTheme = "black-rhino"
      }else{
        this.chartOptionsWonBlocks = {
          theme: {
              mode: 'light'
          },
          colors : ["#06a74d"]
        }
        this.isDarkMode = false,
        this.tableTheme = "polar-bear"
      }
    }
  },

  created() {    
    // function call to get pool stats
    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
    document.documentElement.setAttribute('data-theme', localTheme);
    this.theme = localTheme;
    this.getPoolRewards();
    this.getPoolStats();
    this.updateGraphTheme(this.theme);
  }
};
</script>

<style lang="scss" scoped>
    .theme--light.v-application {
      background: transparent;
    }
</style>