<template>
  <section class="wrapper home_wrapper">
    <div class="container">
      <div class="row row-header">
        <div class="col-sm-12">
          <toggle-button @change="toggleTheme" :value="isDarkMode" :labels="{checked: 'Light', unchecked: 'Dark'}" :color="{checked: '#989898', unchecked: '#383838'}" :width="60" />
        </div>
      </div>
       <div class="row row-margin-bottom">
          <div class="col-sm-12">
            <div class="graphbox-welcome box">
              <h5 class="text-center">{{ $t('login-screen.welcomeTitle') }}</h5>
               <div class="text-center" v-if="!message"> {{ $t('login-screen.configurationTitle') }}<br /> {{launcher_id}} </div>
               <div class="text-center" v-if="message"> {{message}} </div>
              <div class="graph_wrap"></div>
            </div>
          </div>
       </div>
      <div class="row row-margin-bottom" v-if="!message">
          <div class="col-sm-12" v-if="!saveResult">
            <div class="graphBox box">
              <h5 class="text-center">{{ $t('login-screen.nicknameTitle') }}</h5>
              <div class="text-center">
                <input class="form-input" v-model="nickName">
                <br/>
                <button @click="udpateNickname" class="custom-button"> {{ $t('login-screen.saveButton') }} </button>
              </div>
              <div class="graph_wrap"></div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="graphBox box" v-if="saveResult">
              <h5 class="text-center">{{ $t('login-screen.redirectionTitle') }} </h5>
              <div class="graph_wrap"></div>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import https from "https";

export default {
  name: "Login",
  data() {
    return {
      theme: '',
      isDarkMode: false,
      authResult: {},
      launcher_id: '',
      token: '',
      nickName: '',
      message: '',
      errorCode: '',
      saveResult: false
    };
  },

  methods: {
    checkAuth() {
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      this.$axios
        .get("v1/farmer/get?token=" + this.token, {
          httpsAgent: agent,
        })
        .then((res) => {
          this.authResult = res.data;
          this.launcher_id = this.authResult.launcher_id;
          
          localStorage.setItem('token', this.token);
          localStorage.setItem('launcher_id', this.launcher_id);

          if(this.authResult.nickname){
            console.log("Save Nickname")
            this.nickName = this.authResult.nickname;
            localStorage.setItem('nickname', this.nickName)
            this.$router.push("/myfarm");
          }
        })
        .catch(error => {
          if(error.response.status == 404){
            this.errorCode = error.response.status;
            this.message = this.$i18n.t('login-screen.tokenNotFound');
            this.nickName = '';
          }
          if(error.response.status == 401){
            this.errorCode = error.response.status;
            this.message = this.$i18n.t('login-screen.tokenExpired');
          }
        });
    },

    async udpateNickname(){
      let queryBody = {
        "nickname": this.nickName,
        "token": this.token
      };
      const agent = new https.Agent({
        rejectUnauthorized: false,
        method:'POST'
      });
      await this.$axios
        .post("v1/farmer/nickname", queryBody, agent,
        {
          headers : {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
          }
        })
        .then((res) => {
          if(res.data.result == "OK"){
            localStorage.setItem('token', this.token);
            localStorage.setItem('launcher_id', this.launcher_id);
            localStorage.setItem('nickname', this.nickName);
            this.saveResult = true;
            console.log(res.data)
            console.log("Redirecting")
            this.$router.push("/myfarm")  
          }
          else{
            this.saveResult = false;
            this.message = this.$i18n.t('login-screen.failUpdateNickName');
          }
        })
        .catch(error => {
          this.message = error.message;
          console.error("There was an error!", error);
        });
          
        },

    toggleTheme() {
                this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
                document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
                localStorage.setItem('theme', this.theme); // stores theme value on local storage
    }
  },

  created() {
    if (Object.keys(this.$route.query).length) {
      this.token = this.$route.query.token;
      this.checkAuth();
    }
    else{
      this.message = this.$i18n.t('login-screen.noToken');
    }

    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
    document.documentElement.setAttribute('data-theme', localTheme);
    this.theme = localTheme;
  }
};

</script>

<style lang="scss" scoped>
.theme--light.v-application {
      background: transparent;
    }
</style>