<template>
  <section class="wrapper home_wrapper">
    <div class="container">
      <div class="row row-header">
        <div class="col-sm-6">
          <img src="@/assets/images/xchpool-logo-white-bg.jpg" alt="" width="0" height="0" class="store-logo-wh-bg" />
          <toggle-button @change="toggleTheme" :value="isDarkMode" :labels="{checked: 'Light', unchecked: 'Dark'}" :color="{checked: '#989898', unchecked: '#383838'}" :width="60" />
        </div>
        <div class="col-sm-6 align-right">
        </div>
      </div>
      <div class="row row-margin-bottom">
        <div class="col-sm-12">
          <div class="box box-padding">
            <h2 class="text-center">XCHPool Discount Coupon</h2>
            <h3 class="text-center" >Redeem your Discount Coupon CAT and get 0.5% fee for a month (30 days)</h3>
            <div class="text-center" >&nbsp;.</div>
            <div class="text-center" >you can apply this coupon for a single time only each launcher ID</div>
          </div>
        </div>
      </div>

      <div class="row row-margin-bottom">
        <div class="col-sm-12">
          <div class="box box-padding">
            <h5>STEPS:</h5>
            <v-divider></v-divider>
            <div>1. If you haven't yet, <a href='https://xchpool.org/guide-to-chia-farming/'>join the pool</a> first</div>
            <div style='margin-left: 15px'>if you are in the pool already you can skip this step</div>
            <v-divider></v-divider>
            <div>2. Look up your launcher ID <a href='/membersearch'>here</a> </div>
            <div style='margin-left: 15px'>Look up your <b>"Pool Contract Address:"</b> and copy it</div>
            <v-divider></v-divider>
            <div>3. Now go to your wallet where a XCHPool Discount Coupon CAT was dropped</div>
            <div style='margin-left: 15px'>(note: this CAT must have asset ID <a href='https://www.taildatabase.com/tail/0f8676bd61b83d8cc8543cf75a54bb6963ea7c9465eb2da4b84901778ad37d3f'>0f8676bd61b83d8cc8543cf75a54bb6963ea7c9465eb2da4b84901778ad37d3f</a>)</div>
            <v-divider></v-divider>
            <div>4. send EXACTLY 1.0 of this CAT to the Pool Contract Address</div>
            <div style='margin-left: 15px'>(this is the same address you use to make your plots)</div>
            <v-divider></v-divider>
            <div>5. Within a few minutes you will see your fee changed to 0.5% !</div>            
          </div>
        </div>
      </div>

       
    </div>
  </section>
</template>

<script>

export default {
  name: "Coupon",
  data() {
    return {
      theme: '',
      autoRefresh: '',
      isDarkMode: false,
      authResult: {},
      launcher_id: '',
      token: '',
      tokenValid: false,
      nickName: '',
      message: '',
      errorCode: '',
      errorMessage: '',
      difficulties : ['200', '300', '400', '500', '600', '700', '800', '900'],
      difficultySelected: null, 
      currencies: [],
      currencySelected: null, 
      currency_selection: null,
      partials_target: null, 
      min_payout: null,
      target_min_payout: null
    };
  },

  methods: {
    toggleTheme() {
      this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
      document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
      localStorage.setItem('theme', this.theme); // stores theme value on local storage
    }
  },

  created() {

  }
};

</script>

<style lang="scss" scoped>
.theme--light.v-application {
      background: transparent;
    }
</style>